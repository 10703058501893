/* global fabric */

//// example data ////
// data = {
//
//   items: {
//     name: {
//       id : 1,
//       type : 'image',
//       src : sources.me,
//       x: 0.73,
//       y: 0.8,
//       rotation: 356.15,
//       scaleX: 1.13,
//       scaleY: 0.78,
//       index:0,
//       screenRatio : 0.45
//     }
//   },
//
//   texture: {
//     src: 'assets/textures/brick3.png',
//     opacity: 0.9,
//     color: 'blue'
//   },
//
//   color:'green'
//
// }

// ---------------------------------------- //
// init

fabric.Object.prototype.set({
    transparentCorners: false,
    borderColor: 'grey',
    cornerColor: 'grey'
});

// ---------------------------------------- //
// canvas functions

//**** top level ****//

/** intial setup */
export function initCanvas(canvas) {
  canvas.on("object:selected", function(options) {
      options.target.bringToFront();
  });
}

/**
 * data ~ {texture, items}
 * cb ~ (textureObject, canvasItems)
 */
export function loadData(data, canvas, cb) {

  // set color if exists
  if(data.color) {
    setColor(data.color, canvas);
  }

  // load texture if exsits
  if(data.texture) {
    loadTexture(data.texture, canvas, function(texture) {
       _loadItems(texture);
    });
  }else{
    _loadItems(null);
  }

  // load items if exsits
  function _loadItems(texture) {
    var canvasItems = [];
    if(data.items) {
      loadItems(data.items, canvas, canvasItems, function() {
        _return(texture, canvasItems);
      });
    }
  }

  // return created content
  function _return(texture, canvasItems) {
    if(cb)
      cb(texture, canvasItems);
  }

}

/**
 * values ~ everything that is one-way binded (tracked externally)
 * values ~ {texture:{src, opacity, color}, color}
 * data ~ {texture, items}
 */
export function exportData(canvas, canvasItems, screenRatio, values) {
  var items = exportItems(canvasItems, canvas, screenRatio);
  return {
    items:items,
    texture:values.texture,
    color: values.color
  };
}

/** renderAll() wrapper */
export function renderCanvas(canvas) {
  canvas.renderAll();
}

export function exportCanvas(canvas, options) {
  return canvas.toDataURL(options);
}

export function exportCanvasNoBG(canvas, options) {
  var bgImage = canvas.backgroundImage;
  var bgColor = canvas.backgroundColor;
  canvas.backgroundImage = null;
  canvas.backgroundColor = null;
  canvas.renderAll();
  var result = canvas.toDataURL(options);
  canvas.setBackgroundImage(bgImage, function() {
    canvas.backgroundColor = bgColor;
    canvas.renderAll();
  });
  return result;
}


//**** color ****//

/** backgroundColor wrapper */
export function setColor(color, canvas) {
  canvas.backgroundColor = color;
}


//**** texture ****//

/**
 * Loads texture from texture object
 * texture ~ {src, opacity, color}
 * cb ~ resulting texture object
 */
function loadTexture(texture, canvas, cb) {
  setTexture(texture.src, canvas, function(obj) {
    updateTexture(obj, canvas, {opacity:texture.opacity, color:texture.color});
    if(cb)
      cb(obj);
  });
}

/**
 * sets a new image as the texture (properties are removed)
 * cb ~ resulting texture object
 */
export function setTexture(src, canvas, cb) {

  fabric.Image.fromURL(src, function(img) {

    // stretch to screen
    img.set({
      left    : 0,
      top     : 0,
      scaleX : canvas.width/img.width,
      scaleY : canvas.height/img.height
    });

    // set as canvas background
    canvas.setBackgroundImage(img, function() {
      // callback
      if(cb)
        cb(img);
    });

  });

}

/** applies properties and a filter to textureObj */
export function updateTexture(textureObj, canvas, options) {

  if( options.opacity )
    textureObj.opacity = options.opacity;

  if( options.color ) {
    textureObj.filters = [
      new fabric.Image.filters.BlendColor({
        color: options.color,
        mode: 'multiply',
        alpha: 1
      })
    ]
  }
  textureObj.applyFilters();

}


//**** items ****//

/**
 * Adds item to canvas as well as list of canvasItems
 * cb ~ has no parameters (canvasItems are populated via reference)
 */
export function addItem(src, canvas, canvasItems, screenRatio, options, cb) {

  fabric.Image.fromURL(src, function(img) {
    var base = baseScale(img, canvas, screenRatio);

    img.set({
      originX : 'center',
      originY : 'center',
      left    : ((options && options.x != null) ? options.x : 0.5) * canvas.width,
      top     : ((options && options.y != null) ? options.y : 0.5) * canvas.height,
      scaleX  : ((options && options.scaleX != null) ? options.scaleX : 1) * base,
      scaleY  : ((options && options.scaleY != null) ? options.scaleY : 1) * base,
      angle   : (options && options.angle != null) ? options.angle : 0
    });

    // add to canvas and canvasItems list
    canvas.add(img).setActiveObject(img);
    canvasItems.push(img);

    // callback
    if(cb)
      cb(img);
  });

}

/**
 * Removes item from canvas as well as list of canvasItems
 */
export function removeItem(canvasItem, canvas, canvasItems) {
  var canvasItemIndex = canvasItems.indexOf(canvasItem);
  canvasItems.splice(canvasItemIndex, 1);
  canvas.remove(canvasItem);
}

/**
 * Loads items from items object
 * items ~ [{id, type, src, x, y, rotation, scaleX, scaleY, index, screenRatio},...]
 * cb ~ has no parameters (canvasItems are populated via reference)
 */
function loadItems(items, canvas, canvasItems, cb) {

  // clear current canvas items
  canvasItems.length = 0;

  // get number of items in items param
  var numItems = Object.keys(items).length;

  // return if no items
  if(numItems == 0) {
    _onItemsAdded();
    return;
  }

  // add images from items (can implement shapes later)
  var c = 0;  // item counter
	for(var i in items) {
		var item = items[i];

		(function(item) {

			fabric.Image.fromURL(item.src, function(img) {
				var base = baseScale(img, canvas, item.screenRatio);

				img.set({
					originX : 'center',
					originY : 'center',
					left    : item.x * canvas.width,
					top     : item.y * canvas.height,
					scaleX  : item.scaleX * base,
					scaleY  : item.scaleY * base,
					angle   : item.rotation
				});

				// add item & id keys to fabric object
				img.item = item;
				img.id = item.id;

				// add to canvas and canvasItems list
				canvas.add(img);  //.setActiveObject(img);
				canvasItems.push(img);

				// debug tracking
				// if(i==1)
				// 	testObj = img;

				// all images inserted
        c++;
				if(c==numItems) {
          _onItemsAdded();
        }

			});

		})(item);

	}

	// order items according to item z-index indexes
	function _onItemsAdded() {
		for(var i in canvasItems) {
			var canvasItem = canvasItems[i];
			canvasItem.moveTo( canvasItem.item.index );
		}
    if(cb)
      cb();
	}

}

/**
 * Exports items object from canvasItems array
 * canvasItems ~ [Fabric.Object, ...]
 * note: screenRatio applies when canvasItem doesn't have it's own
 */
function exportItems(canvasItems, canvas, screenRatio) {
	var items = {};
	for(var i in canvasItems) {
		var canvasItem = canvasItems[i];
		items[i] = {
			id : i,
			type : 'image',  // image, shape, etc
			src : canvasItem.getSrc(),
			x : getX(canvasItem, canvas),
			y : getY(canvasItem, canvas),
			rotation : getRotation(canvasItem),
			scaleX : getScaleX(canvasItem, canvas, null, screenRatio),
			scaleY : getScaleY(canvasItem, canvas, null, screenRatio),
			index : canvas.getObjects().indexOf(canvasItem),
			screenRatio : getScreenRatio(canvasItem, screenRatio)
		};
	}
	return items;
}

// ---------------------------------------- //
// sizing functions

function getScaleX(img, canvas, screenRatio, defaultRatio) {
  return img.scaleX / baseScale(img, canvas, screenRatio, defaultRatio);
}
function getScaleY(img, canvas, screenRatio, defaultRatio) {
  return img.scaleY / baseScale(img, canvas, screenRatio, defaultRatio);
}

function getX(img, canvas) { return img.left / canvas.width; }
function getY(img, canvas) { return img.top  / canvas.height; }

function getRotation(img) { return img.angle; }

function baseScale(img, canvas, screenRatio, defaultRatio) {

	if( screenRatio ) {                               // 1. ratio provided
		return (img.width > img.height) ?
			canvas.width  * screenRatio / img.width :
			canvas.height * screenRatio / img.height;
  }else if( img.item ) {                            // 2. object has ratio
		return (img.width > img.height) ?
			canvas.width  * img.item.screenRatio / img.width :
			canvas.height * img.item.screenRatio / img.height;
  }else if(defaultRatio) {                          // 3. fallback
    return (img.width > img.height) ?
      canvas.width  * defaultRatio / img.width :
      canvas.height * defaultRatio / img.height;
  }

}

function getScreenRatio(img, defaultRatio) {
	if( img.item ) {
		return img.item.screenRatio;
	}else{
		return defaultRatio;
	}
}
