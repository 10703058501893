<template>

  <div
    class="color-blocks center-scroll-x"
    :class="{'dark':dark}"
    :style="{
      'padding-top':paddingTop+'px',
      'padding-bottom':paddingBottom+'px',
    }"
  >
    <color-block v-for="(color, i) in colors"
      :color=color
      :selected="value==color"
      @click="() => $emit('input', color)"
      :key="i"
    ></color-block>
  </div>

</template>

<script>
import {colors} from '@/static/customizer'

import ColorBlock from '@/components/customizer/ColorBlock.vue'

export default {
  name:'color-blocks',
  components: {
    ColorBlock,
  },
  props: {
    value: {type:String, default:null},
    colors : {type:Array, default:() => colors},
    paddingTop: {Type:Number, default:7},
    paddingBottom: {Type:Number, default:5},
    dark: {Type:Boolean, default:false},
  },
}
</script>

<style scoped>

.color-blocks {
  padding-left:  5px;
  padding-right: 5px;
}

</style>
