<template>

  <div class="rel-container" style="overflow-y:auto;">

    <div class="pos-rel" style="height:50%">

      <v-layout fill-height justify-center align-center>

        <div>

            <div class="text-xs-center blue--text text--darken-1 headline">
              Personal
            </div>
            <div class="text-xs-center blue--text text--darken-2 display-2">
              99&#162;
            </div>
            <div class="text-xs-center grey--text text--darken-1 subheading">
              Personalize to fit your recipient
            </div>
            <div class="text-xs-center pos-rel pt-3">
              Create your own wrapping paper<br />
              Select custom colors and textures<br />
              Select custom card fonts<br />
              Write a personal card<br />
              <v-btn large color="info" @click="$emit('submit', 'personal')">Personal</v-btn>
            </div>

        </div>

      </v-layout>

      <div class="line-bottom"></div>

    </div>


    <div style="height:50%">

      <v-layout fill-height justify-center align-center>

      <div>

          <div class="text-xs-center green--text text--darken-1 headline">
            Try it out
          </div>
          <div class="text-xs-center green--text display-2">
            Free
          </div>
          <div class="text-xs-center grey--text text--darken-1 subheading">
            Try out digital gift wrap for free
          </div>
          <div class="text-xs-center pos-rel pt-3">
            Write a personal card<br />
            <v-btn @click="$emit('submit', 'free')">Free</v-btn>
          </div>


      </div>

      </v-layout>

    </div>



  </div>

</template>

<script>
export default {
  name:'gift-customization-mode'
}
</script>
