/*
I think this module keeps the store stage in sync when data.gift is edited
^
Which actually isn't entirely necessary ~ ie, the state doesn't need to exist in two places...

^
I think I thought I needed a watcher, so I had to leave the data inside the component ~~~ cuz Vuex has no watchers

-------------

NOTE: Leaving this file untouched for the gx3d integration ~ it's ugly, but it is actually very decoupled
*/

import store from '@/store/store'

import {inFromString} from '@/misc/from-string'
import {clone, cloneKeys} from '@/misc/clone'

// debug: using two for viewing store properties via computed props
import { two } from '@/misc/vuex.two'
function customizerStagePropertyTwo(giftKey) {
  return two(store,
    'customizerStageProperty',
    'setCustomizerStageProperty',
    giftKey, {key:giftKey}, 'value');
}

// ------------------------------------------------------- //

function setCustomizerStageProperty(key, value, ignoreSave) {
  store.commit('setCustomizerStageProperty', {key, value, ignoreSave});
}

function simpleGiftBind(property) {
  let watchers = {};
  watchers['gift.' + property] = function(value) {
    if(this.bind) {
      setCustomizerStageProperty(property, value)
    }
  };
  return watchers;
}

function functionalGiftBind(property, keyFunction) {
  let watchers = {};
  watchers['gift.' + property] = function(value) {
    if(this.bind) {
      var key = keyFunction(this);
      if( key != null ) {
        if( key instanceof Array ) {
          for(let i in key) { setCustomizerStageProperty(key[i], value); }
        }else{
          setCustomizerStageProperty(key, value);
        }
      }
    }
  };
  return watchers;
}

function giftCustomizationBind(property) {

  return functionalGiftBind('customization.' + property, (me) => {
    let references = [];

    if( 'customization' in me.stage )
      references.push('customization' + '.' + property);

    if( 'shared' in me.stage  )
      if( inFromString(me.stage.shared, property)  )
        references.push('shared' + '.' + property);

    if( me.customizationMode )
      if( me.customizationMode === 'personal' )
        if( 'personal' in me.stage )
          if( inFromString(me.stage.personal, property) )
            references.push('personal' + '.' + property);

    if( me.customizationMode )
      if( me.customizationMode === 'free' )
        if( 'free' in me.stage )
          if( inFromString(me.stage.free, property) )
            references.push('free' + '.' + property);

    return references;
  });

}

// ------------------------------------------------------- //

// totally unedited gift object ~ used for syncing
const nullGift = {
  senderEmail: null,
  receiverEmail: null,
  senderName: null,
  receiverName: null,
  anonymous: false,

  contentType: null,
  contentInfo: null,

  deliveryType: null,
  deliveryTime: null,
  deliveryTimezone: null,

  screenshot: null,
  screenshotWide: null,

  customizationMode: null,

  customization: {

    preset: null,
    prewrap: null,

    presetEdited: false,
    prewrapEdited: false,

    builder: null,
    usingCustomWrap: false,

    wrap: {src:null, repeat:1},
    ribbon: null,
    color: null,
    texture: {
      src: null,
      opacity: 0,
      color: null,
    },
    back: {
      src: null,
      opacity: 0,
      color: null
    },
    front: {
      src: null,
      opacity: 0,
      color: null
    },

    card: {
      front: {
        text: null,
        fontColor: null,
        fontFamily: null,
        fontSize: 1,
        lineHeight: 1,
        color: null,
      },
      inside: {
        text: null,
        fontColor: null,
        fontFamily: null,
        fontSize: 1,
        lineHeight: 1,
        color: null,
      },
      signature: {
        text: null
      }
    }

  }

};

// ------------------------------------------------------- //

export default {

  data() {
    return {
      bind: false,
      gift: clone(nullGift),
    }
  },

  computed: {

    stage() {
      return store.getters.customizerStage;  // the actual stage
    },

    mode() {
      return store.getters.customizerMode;  // 'new' or 'edit'
    },

    customizationMode() {
      return store.getters.customizerCustomizationMode;  // 'personal' or 'free'
    },

    // debug store properties
    _senderEmail:customizerStagePropertyTwo('senderEmail'),

  },

  watch: {

    customizationMode() {
      // load up new stuff upon change...
      this.sync();
    },

    ...simpleGiftBind('senderEmail'),
    ...simpleGiftBind('receiverEmail'),
    ...simpleGiftBind('senderName'),
    ...simpleGiftBind('receiverName'),
    ...simpleGiftBind('anonymous'),
    ...simpleGiftBind('contentInfo'),
    ...simpleGiftBind('contentType'),
    ...simpleGiftBind('deliveryType'),
    ...simpleGiftBind('deliveryTime'),
    ...simpleGiftBind('deliveryTimezone'),
    ...simpleGiftBind('screenshot'),
    ...simpleGiftBind('screenshotWide'),
    ...simpleGiftBind('customizationMode'),

    ...giftCustomizationBind('preset'),
    ...giftCustomizationBind('prewrap'),
    ...giftCustomizationBind('presetEdited'),
    ...giftCustomizationBind('prewrapEdited'),
    ...giftCustomizationBind('builder'),
    ...giftCustomizationBind('usingCustomWrap'),

    ...giftCustomizationBind('color'),
    ...giftCustomizationBind('ribbon'),

    ...giftCustomizationBind('wrap.src'),
    ...giftCustomizationBind('wrap.repeat'),
    ...giftCustomizationBind('texture.src'),
    ...giftCustomizationBind('texture.opacity'),
    ...giftCustomizationBind('texture.color'),
    ...giftCustomizationBind('back.src'),
    ...giftCustomizationBind('back.opacity'),
    ...giftCustomizationBind('back.color'),
    ...giftCustomizationBind('front.src'),
    ...giftCustomizationBind('front.opacity'),
    ...giftCustomizationBind('front.color'),
    ...giftCustomizationBind('card.front.text'),
    ...giftCustomizationBind('card.front.fontColor'),
    ...giftCustomizationBind('card.front.fontFamily'),
    ...giftCustomizationBind('card.front.fontSize'),
    ...giftCustomizationBind('card.front.lineHeight'),
    ...giftCustomizationBind('card.front.color'),
    ...giftCustomizationBind('card.inside.text'),
    ...giftCustomizationBind('card.inside.fontColor'),
    ...giftCustomizationBind('card.inside.fontFamily'),
    ...giftCustomizationBind('card.inside.fontSize'),
    ...giftCustomizationBind('card.inside.lineHeight'),
    ...giftCustomizationBind('card.inside.color'),
    ...giftCustomizationBind('card.signature.text'),

  },

  mounted() {
    // debug: refresh gift for testing
    //store.commit('setCustomizerStage', null);

    // start customizer
    this.start();

    // sync local properties
    this.sync();

  },

  methods: {

    start() {
      store.dispatch('startCustomizer');
    },

    sync() {
      return new Promise((resolve) => {
        this.bind = false;
        if( this.stage ) {
          // start with a fresh gift
          this.gift = clone(nullGift);

          this.gift.senderEmail       = this.stage.senderEmail;
          this.gift.receiverEmail     = this.stage.receiverEmail;
          this.gift.senderName        = this.stage.senderName;
          this.gift.receiverName      = this.stage.receiverName;
          this.gift.contentType       = this.stage.contentType;
          this.gift.contentInfo       = this.stage.contentInfo;
          this.gift.deliveryType      = this.stage.deliveryType;
          this.gift.deliveryTime      = this.stage.deliveryTime;
          this.gift.screenshot        = this.stage.screenshot;
          this.gift.screenshotWide    = this.stage.screenshotWide;
          this.gift.customizationMode = this.stage.customizationMode;

          if( 'customization' in this.stage ) {
            cloneKeys(this.stage.customization, this.gift.customization);
          }else

          if( this.customizationMode ) {
            if( this.customizationMode === 'personal' && 'personal' in this.stage ) {
              cloneKeys(this.stage.personal, this.gift.customization);
            }

            if( this.customizationMode === 'free' && 'free' in this.stage ) {
               cloneKeys(this.stage.free, this.gift.customization);
            }

            if( 'shared' in this.stage ) {
              cloneKeys(this.stage.shared, this.gift.customization);
            }
          }

        }else{
          console.log('STAGE IS NULL. CANNOT SYNC.');
        }

        this.$nextTick(() => {
          this.bind = true;
          resolve();
        })
      })
    },

  }

}
