<template>

  <div class="bottom-steps blue-grey" :style="[{'height':height+'px'}]" >
    <v-layout fill-height class="px-4">

      <v-flex v-for="(step,i) in steps" :key="i">
        <v-btn class="bottom-steps-btn" block flat @click="$emit('input', i)" :input-value="value==i">
          <v-layout column :class="[value==i ? 'white--text': 'blue-grey--text text--lighten-3']">
            <v-flex><v-icon :style="[value==i ? {'font-size':'30px'} : {}, ]">{{step.icon}}</v-icon></v-flex>
            <v-flex :style="[value==i ? {} : {}, ]">{{step.title}}</v-flex>
          </v-layout>
        </v-btn>
      </v-flex>

    </v-layout>
  </div>

</template>

<script>
// let ua = window.navigator.userAgent;
// let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
// let webkit = !!ua.match(/WebKit/i);
// let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

export default {
  name:'my-steps-bar',
  props: {
    value:Number,
    steps:Array,
    height:Number,
  },
}

</script>

<style>

.bottom-steps {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.bottom-steps-btn {
  margin: 0;
  border-radius: 0;
  height:100%;
  min-width: 10px;
  font-weight: 400;
  line-height: 16px;
}

.bottom-steps-btn i {
  -webkit-transition: all 0.5s !important;
  -moz-transition:    all 0.5s !important;
  -o-transition:      all 0.5s !important;
  transition:         all 0.5s !important;
}

</style>
