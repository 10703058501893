<template>

  <div class="box-wrap">

    <div style="height: 200px; overflow-y: auto;">
      <v-container fluid grid-list-sm class="pa-1">
        <v-layout row wrap>
          <v-flex v-for="(wrp, i) in wraps" :key="i" xs3 sm2 md2 style="max-width:100px;">
            <div
              class="wrap-preview rel-container"
              :class="{'active':wrp.src === wrap.src}"
              v-ripple
              @click="$emit('update:wrap', wrp)"
            >
              <img :src="wrp.src" width="100%" height="100%">
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    
    <v-layout align-center class="border-top pa-1 white">
      <v-btn color="grey darken-1" dark @click="$emit('cancel')">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="$emit('submit')">Ok</v-btn>
    </v-layout>

  </div>

</template>

<script>
import {wraps} from '@/static/customizer'

export default {
  name:'box-wrap',
  props: {
    wraps:{type:Array, default:()=>wraps},
    wrap:{type:Object, default:()=>wraps[0]},
  },
}

</script>

<style scoped>

.wrap-preview {
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.wrap-preview.active {
  border-style: solid;
  border-width: 2px;
  border-color: grey;
}

</style>
