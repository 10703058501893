export default function exportRegion(image, canvas, alpha) {

  // mask canvas
  var mc = cloneCanvas( canvas );
  var mctx = mc.getContext('2d');
  var imageData = mctx.getImageData(0, 0, mc.width, mc.height);
  var data = imageData.data;

  // loop vars
  var i,j;

  // normalize mask alpha up to 1
  for (i = 0; i < data.length; i += 4) {
    data[i + 3] = data[i + 3] / alpha;
  }
  mctx.putImageData(imageData, 0, 0);

  // set content to get cropped out and add image
  mctx.globalCompositeOperation = 'source-out';
  mctx.drawImage(image,0,0);

  //// crop region bounding box
  var widthx4 = mc.width*4;

  // top
  var pxTop = 0;
  for (i = 0; i < data.length; i += 4) {
    if( data[i + 3] < 255 ) { pxTop = i; break;}
  }

  // bottom
  var pxBottom = 0;
  for (i = data.length; i > 0; i -= 4) {
    if( data[i + 3] < 255 ) { pxBottom = i; break;}
  }

  // right
  var pxRight = 0;
  for (i = 0; i < widthx4; i += 4) {
    for (j = i; j < data.length; j += widthx4) {
      if( data[j + 3] < 255 ) { pxRight = i; break; }
    }
  }

  // left
  var pxLeft = 0;
  for (i = widthx4; i > 0; i -= 4) {
    for (j = i; j < data.length; j += widthx4) {
      if( data[j + 3] < 255 ) { pxLeft = i; break; }
    }
  }
  
  // convert to rows/columns
  var top    = Math.floor( pxTop    / widthx4 );
  var bottom = Math.floor( pxBottom / widthx4 );
  var right  = Math.floor((pxRight % widthx4) / 4);
  var left   = Math.floor((pxLeft  % widthx4) / 4);

  // export cropped region
  var ec   = document.createElement('canvas');
  var ectx = ec.getContext('2d');

  var exportWidth = right - left;
  var exportHeight = bottom - top;

  ec.width  = exportWidth;
  ec.height = exportHeight;

  ectx.drawImage(
    mc,
    left, top, exportWidth, exportHeight,
    0, 0, exportWidth, exportHeight
  );

  return ec.toDataURL();

}

// ------------------------------ //
// util functions

function cloneCanvas(oldCanvas) {

    //create a new canvas
    var newCanvas = document.createElement('canvas');
    var context = newCanvas.getContext('2d');

    //set dimensions
    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;

    //apply the old canvas to the new one
    context.drawImage(oldCanvas, 0, 0);

    //return the new canvas
    return newCanvas;
}
