<template>

  <div class="gift-image">

    <h2 class="subheading">Gift Image File</h2>

    <div style="text-align:center;">

      <div><a :href="fileUrl" :download="fileName">{{fileName}}</a></div>
      <div><img v-if="fileUrl" :src="fileUrl" height="200px"></div>
      <div v-if="!(fileUrl || fileProcessing)" class="grey--text text--lighten-1">&lt;Click to Upload File	&gt;</div>
      <div v-if="fileProcessing">
        <v-progress-circular
          indeterminate
          :size="28"
          color="blue"
        ></v-progress-circular>
      </div>
      <input
        type="file"
        style="position:absolute; display:none;"
        accept="image/png, image/jpeg, image/gif, image/jpg"

        ref="fileInput"
        @change="fileSelected"
      >
      <v-btn color="info" @click="$refs.fileInput.click()">Select File</v-btn>

    </div>


  </div>

</template>

<script>
import { MAX_FILE_UPLOAD_SIZE } from '@/static/customizer'
import heap from '@/libs/heap'

import Compress from 'compress.js'

export default {
  name:'gift-image',
  data() {
    return {
      file:null,
      fileName:this.$store.getters.customizerStageProperty('contentInfo.file.name'),
      fileUrl: this.$store.getters.customizerStageProperty('contentInfo.file.url'),

      fileProcessing:false,
    }
  },
  methods: {
    fileSelected(e) {
      let file = e.target.files[0];
      let fileSizeExceedsLimit = false;

      // file type
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert('Please use gif,jpeg,jpg,png,bmp file format.');
        return false;
      }

      // same file
      if( file.name === this.fileName ) {
        alert('File with the same name already uploaded. Please select another file.');
        return;
      }

      // limit file size
      if(file.size > MAX_FILE_UPLOAD_SIZE ) {
        // alert('File size is ' + Math.round((file.size/1000000)*100)/100 +
        //       ' MB. File upload size limit is ' + Math.round((MAX_FILE_UPLOAD_SIZE/1000000)*100)/100 +
        //       ' MB');
        // return;
        fileSizeExceedsLimit = true;
      }

      // promise chain...
      return Promise.resolve()

      // compress if needed
      .then(() => {
        if(fileSizeExceedsLimit) {
          this.fileProcessing = true;
          const compress = new Compress();
          return compress.compress([file], { size: 1.5 }) // the max size in MB, defaults to 2MB
          .then((data) => {
            let compressedFile = data[0];
            let dataURL   = compressedFile.data;
            let extension = compressedFile.ext;
            let prevName = file.name;
            file = Compress.convertBase64ToFile(dataURL, extension);
            file.name = prevName;
            //file = fileData.dataURLtoBlob(compressedFileDataURL);
          })
        }
      })

      // check size one last time
      .then(() => {
        if(file.size > MAX_FILE_UPLOAD_SIZE ) {
          alert('File size is ' + Math.round((file.size/1000000)*100)/100 +
                ' MB. File upload size limit is ' + Math.round((MAX_FILE_UPLOAD_SIZE/1000000)*100)/100 +
                ' MB');
          return Promise.reject('FILE_MAX_UPLOAD_SIZE_EXCEEDED');
        }
      })

      // stage onto heap
      .then(() => {
        this.file = file;
        this.fileName = file.name;
        this.fileUrl = heap.put(this.file, '_', '/gift/content');
        this.fileProcessing = false;
      })

    },

    getData() {

      if( this.file ) {

        // write to heap
        heap.write(this.file, '_', '/gift/content').then((blobUrl) => {
          this.fileUrl = blobUrl;
          this.$emit('getData', { file: { name:this.fileName, url:this.fileUrl } });
        });

      }else{
        this.$emit('getData', { file: { name:this.fileName, url:this.fileUrl } });
      }

    },

  }
}

</script>
