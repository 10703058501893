<template>
  
  <div
    v-ripple
    class="color-block"
    :class="{'selected':selected}"
    :style="{'background-color':color}"
    @click="$emit('click')"
  >
  </div>

</template>

<script>
export default {
  name:'color-block',
  props: {
    selected: {type:Boolean, default:false},
    color: {type:String, default:'#FF00FF'},
  }
}
</script>

<style scoped>

.color-block {
  display: inline-block;
  width:  44px;
  height: 44px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  margin-left:  3px;
  margin-right: 3px;
  cursor: pointer;
}
.color-block.selected {
  border-width: 3px;
  border-color: grey;
}

</style>
