<template>

  <div class="gift-pdf">

    <h2 class="subheading">Gift PDF</h2>

    <div style="text-align:center;">

      <div><a :href="fileUrl" :download="fileName">{{fileName}}</a></div>
      <div><embed v-if="fileUrl" :src="fileUrl" type="application/pdf" width="300px" height="200px" view=Fit /></div>
      <div v-if="!fileUrl" class="grey--text text--lighten-1">&lt;Click to Upload File	&gt;</div>
      <input
        type="file"
        style="position:absolute; display:none;"
        accept="application/pdf"

        ref="fileInput"
        @change="fileSelected"
      >
      <v-btn color="info" @click="$refs.fileInput.click()">Select File</v-btn>

    </div>

  </div>

</template>

<script>
import { MAX_FILE_UPLOAD_SIZE } from '@/static/customizer'

import heap from '@/libs/heap'

export default {
  name:'gift-pdf',
  data() {
    return {
      file:null,
      fileName:this.$store.getters.customizerStageProperty('contentInfo.file.name'),
      fileUrl: this.$store.getters.customizerStageProperty('contentInfo.file.url'),
    }
  },
  mounted() {
  },
  methods: {
    
    fileSelected(e) {
      let file = e.target.files[0];

      // file type
      if (!/\.(pdf|PDF)$/.test(e.target.value)) {
        alert('Please use pdf file format.');
        return;
      }

      // same file
      if( file.name === this.fileName ) {
        alert('File with the same name already uploaded. Please select another file.');
        return;
      }

      // limit file size
      if(file.size > MAX_FILE_UPLOAD_SIZE ) {
        alert('File size is ' + Math.round((file.size/1000000)*100)/100 +
              ' MB. File upload size limit is ' + Math.round((MAX_FILE_UPLOAD_SIZE/1000000)*100)/100 +
              ' MB. We suggest removing pages from PDF file and trying again.');
        return;
      }

      // stage onto heap
      this.file = file;
      this.fileName = file.name;
      this.fileUrl = heap.put(this.file, '_', '/gift/content');

    },

    getData() {

      if( this.file ) {

        // write to heap
        heap.write(this.file, '_', '/gift/content').then((blobUrl) => {
          this.fileUrl = blobUrl;
          this.$emit('getData', { file: { name:this.fileName, url:this.fileUrl } });
        });

      }else{
        this.$emit('getData', { file: { name:this.fileName, url:this.fileUrl } });
      }

    },

  }
}

</script>
