<template>
  <div class="captcha" ref="captcha"></div>
</template>

<script>
/* global grecaptcha, BBus */

export default {
  name:'captcha',
  props: {
    id: {type:String, default:'captcha'},
  },
  mounted() {
    // render recaptcha if possible. otherwise, wait until recaptcha is ready.
    if('render' in grecaptcha) { this.renderRecaptcha(); }
    else{ BBus.on('RECAPTCHA_LOADED', 'cart', this.renderRecaptcha.bind(this)); }
  },
  methods: {

    verifyCallback(token) {
      this.$store.commit('setCaptchaToken', {id:this.id, token:token});
    },

    renderRecaptcha() {
      grecaptcha.render(this.$refs.captcha, {
        // HERE: captcha site key
        'sitekey' : '6Le4OT0UAAAAABG1pCyNPsazYNyNVlYmHQmTFTaR',
        'callback' : this.verifyCallback,
      });
    },

  }
}
</script>
