<template>

  <div class="image-blocks center-scroll-x"
    :style="{
      'padding-top':paddingTop+'px',
      'padding-bottom':paddingBottom+'px',
    }"
  >

    <div
      style="
        width:60px;
        height:60px;
        display:inline-block;
        position:relative;
        "
        class="grey lighten-3 image-block"
        :class="{'selected':value==='/assets/misc/blank.png'}"
        @click="() => $emit('input', '/assets/misc/blank.png')"
    >
      <div class="abs-container">
        <v-layout class="text-xs-center" fill-height justify-center align-center>
          <v-icon color="grey" style="font-size:40px;">clear</v-icon>
        </v-layout>
      </div>
    </div>

    <img v-for="(src, i) in images"
      v-ripple
      :key="i"
      :src="src"
      height="60"
      width="60"
      style="background-color:grey;"
      class="image-block d-inline-block"
      :class="{'selected':value===src}"
      @click="() => $emit('input', src)"
    />

  </div>

</template>

<script>

export default {
  name:'image-blocks',
  props: {
    value: {type:String, default:null},
    images: {type:Array, default:null},
    paddingTop: {Type:Number, default:7},
    paddingBottom: {Type:Number, default:5},
  },
}
</script>

<style scoped>

.image-blocks {
  padding-left:  5px;
  padding-right: 5px;
}

.image-block {
  margin-left:  3px;
  margin-right: 3px;
  cursor: pointer;
}
.image-block.selected {
  border-style: solid !important;
  border-width: 1px !important;
  border-color: grey !important;
}

</style>
