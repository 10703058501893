/*
B3Interface.js
I think idea of this file was to keep B3 in sync when component.data.gift is mutated
^
Specifically by calling setter funcions and providing a bulk update when needed

^
The latest Gx3d supports pushing any customizations and then processing & flushing them with dirty checking
^
So, the workflow in Gx3d is to always push an entire object, then Gx3d updates what needs to be updated

NOTE: B3 is not a class, it's an instance of Bitwrap3js
*/

/* global B3 */

import gx3ds from '@/libs/gx3ds'
import { toGx3d } from '@/libs/gx3d-translator'

import {B3_DEFAULTS} from '@/static/customizer'

import {fromString} from '@/misc/from-string'
import {clone} from '@/misc/clone'

// ------------------------------------------------------- //
// util functions

function callB3Function(func, value) {
  if(B3 && value) {
    // console.log( 'callB3Function: ' + func + ' ' + value );
    B3[func](value);
  }
}

function giftCustomizationToB3Bind(property, func) {
  let watchers = {};
  watchers['gift.customization.' + property] = async function(value) {

    // OLD
    // callB3Function(func, value);

    // NEW
    const gx3d = gx3ds.main.instance;
    await gx3d.onLoaded();
    let customization = {};
    if(this.gift) {
      if(this.gift.customization) {
        customization = this.gift.customization;
      }
    }
    gx3d.pushCustomizations( toGx3d(customization, this.gift.customizationMode) );
    await gx3d.processCustomization();

  };
  return watchers;
}

function giftCustomizationCardBind(giftProp, b3CardDetailsProp, isScalar) {
  let watchers = {};
  watchers['gift.customization.' + giftProp] = async function(value) {
    if(value) {

      // if array, set all B3 values in array. otherwise, set single value...
      if(b3CardDetailsProp instanceof Array) {
        if(isScalar) {
          let b3DefaultValue = fromString(B3_DEFAULTS, 'cardDetails.' + b3CardDetailsProp[0]);
          value = b3DefaultValue * value;
        }
        for(let i in b3CardDetailsProp) {
          let prop = b3CardDetailsProp[i];
          fromString(this, 'b3CardDetails.' + prop, value);
        }
      }else{
        if(isScalar) {
          let b3DefaultValue = fromString(B3_DEFAULTS, 'cardDetails.' + b3CardDetailsProp);
          value = b3DefaultValue * value;
        }
        fromString(this, 'b3CardDetails.' + b3CardDetailsProp, value);
      }

      // NOTE: B3_DEFAULTS values are only utilized when isScalar

      // NOTE: text scaling is accounted for with "isScalar" and "value = b3DefaultValue * value;"

      // OLD
      // B3.setCardDetails( this.b3CardDetails );

      // DEBUG
      // console.log('value:');
      // console.log(giftProp);
      // console.log(b3CardDetailsProp);
      // console.log(value);

      // NEW
      const gx3d = gx3ds.main.instance;
      await gx3d.onLoaded();
      let customization = {};
      if(this.gift) {
        if(this.gift.customization) {
          customization = this.gift.customization;
        }
      }
      gx3d.pushCustomizations( toGx3d(customization, this.gift.customizationMode) );
      await gx3d.processCustomization();

    }
  };
  return watchers;
}

// function giftCustomizationToLocalBind(giftProp, localProp) {
//   let watchers = {};
//   watchers['gift.customization.' + giftProp] = function(value) {
//     fromString(this, localProp, value);
//   };
//   return watchers;
// }

// b3CardDetails:
// store card object locally, when updated ~ send to B3

// ------------------------------------------------------- //

export default {

  data() {
    return {

      // tried delaying B3 calls to a queue to fix twitching gift wrap ~ didn't work
      // b3Bind:true,
      // b3Queue:[],

      b3CardDetails: {},

    }
  },

  watch: {

    ...giftCustomizationToB3Bind('color', 'setSceneBackgroundColor'),
    ...giftCustomizationToB3Bind('ribbon', 'setRibbonColor'),
    ...giftCustomizationToB3Bind('wrap.src', 'setGiftwrapTextureURL'),
    ...giftCustomizationToB3Bind('wrap.repeat', 'setGiftwrapTextureRepeat'),
    ...giftCustomizationToB3Bind('texture.src', 'setSceneBackgroundImageURL'),
    ...giftCustomizationToB3Bind('texture.opacity', 'setSceneBackgroundImageOpacity'),
    ...giftCustomizationToB3Bind('back.src', 'setSceneBackImageURL'),
    ...giftCustomizationToB3Bind('back.opacity', 'setSceneBackImageOpacity'),
    ...giftCustomizationToB3Bind('front.src', 'setSceneFrontImageURL'),
    ...giftCustomizationToB3Bind('front.opacity', 'setSceneFrontImageOpacity'),

    ...giftCustomizationCardBind('card.front.text', 'textContent.title.text'),
    ...giftCustomizationCardBind('card.front.fontColor', 'textContent.title.color'),
    ...giftCustomizationCardBind('card.front.fontFamily', 'textContent.title.fontFamily'),
    ...giftCustomizationCardBind('card.front.fontSize', 'textContent.title.fontSize', true),
    ...giftCustomizationCardBind('card.front.lineHeight', 'textContent.title.lineHeight', true),
    ...giftCustomizationCardBind('card.front.color', 'frontCoverBackgroundColor'),

    ...giftCustomizationCardBind('card.inside.text', 'textContent.inside.text'),
    ...giftCustomizationCardBind('card.inside.fontColor', ['textContent.signature.color', 'textContent.inside.color']),
    ...giftCustomizationCardBind('card.inside.fontFamily', 'textContent.inside.fontFamily'),
    ...giftCustomizationCardBind('card.inside.fontSize', 'textContent.inside.fontSize', true),
    ...giftCustomizationCardBind('card.inside.lineHeight', 'textContent.inside.lineHeight', true),

    ...giftCustomizationCardBind('card.inside.color', ['insideLeftBackgroundColor', 'insideRightBackgroundColor']),

    ...giftCustomizationCardBind('card.signature.text', 'textContent.signature.text'),

  },

  methods: {

    // used in syncB3
    // used when syncing the screenshot instance in Customizer
    extractB3Settings() {
      let settings = clone(B3_DEFAULTS);

      let map = {

        'sceneBackgroundColor'        : 'color',
        'ribbonColor'                 : 'ribbon',
        'giftwrapTextureURL'          : 'wrap.src',
        'giftwrapTextureRepeat'       : 'wrap.repeat',
        'sceneBackgroundImageURL'     : 'texture.src',
        'sceneBackgroundImageOpacity' : 'texture.opacity',
        //''                          : 'texture.color',
        'sceneBackImageURL'           : 'back.src',
        'sceneBackImageOpacity'       : 'back.opacity',
        //''                          : 'back.color',
        'sceneFrontImageURL'          : 'front.src',
        'sceneFrontImageOpacity'      : 'front.opacity',
        //''                          : 'front.color',

        'cardDetails.textContent.title.text'        : 'card.front.text',
        'cardDetails.textContent.title.color'       : 'card.front.fontColor',
        'cardDetails.textContent.title.fontFamily'  : 'card.front.fontFamily',
        'cardDetails.textContent.title.fontSize'    : 'card.front.fontSize',
        'cardDetails.textContent.title.lineHeight'  : 'card.front.lineHeight',
        'cardDetails.frontCoverBackgroundColor'     : 'card.front.color',

        'cardDetails.textContent.inside.text'       : 'card.inside.text',
        'cardDetails.textContent.inside.color'      : 'card.inside.fontColor',
        'cardDetails.textContent.inside.fontFamily' : 'card.inside.fontFamily',
        'cardDetails.textContent.inside.fontSize'   : 'card.inside.fontSize',
        'cardDetails.textContent.inside.lineHeight' : 'card.inside.lineHeight',
        'cardDetails.insideLeftBackgroundColor'     : 'card.inside.color',
        'cardDetails.insideRightBackgroundColor'    : 'card.inside.color',

        'cardDetails.textContent.signature.text'    : 'card.signature.text',
        'cardDetails.textContent.signature.color'   : 'card.inside.fontColor',

      };

      let scalars = {
        'card.front.fontSize':true,
        'card.front.lineHeight':true,
        'card.inside.fontSize':true,
        'card.inside.lineHeight':true,
      };

      // set settings values
      for(let i in map) {
        let b3Key = i, giftKey = map[i];
        let giftValue = fromString(this.gift.customization, giftKey);
        if(giftValue) {
          // scalar values
          if(giftKey in scalars) {
            let b3DefaultValue = fromString(B3_DEFAULTS, b3Key);
            giftValue = b3DefaultValue * giftValue;
          }
          // set in settings
          fromString(settings, b3Key, giftValue);
        }
      }

      return settings;
    },

    // sync ~ update everything in B3
    syncB3() {
      let settings = this.extractB3Settings();

      // extract card details to local version
      this.b3CardDetails = clone(settings.cardDetails);

      // debug
      console.log( 'initial settings:' );
      console.log( settings );

      B3.setAllSettings(settings);
    },

    // unused ~ tried delaying bulk calls to prevent twitching
    // rebindB3() {
    //   this.$nextTick(() => {
    //     this.b3Bind = true;
    //     while(this.b3Queue.length > 0) {
    //       let o = this.b3Queue.pop();
    //       callB3Function(o.func, o.value);
    //     }
    //   })
    // },

  }

}
