<template>

  <div v-ripple @click="$emit('click', event)" class="d-inline-block">
    <button class="pos-rel" :style="buttonStyle">
      <div class="abs-container">

        <v-layout column fill-height>

          <v-flex fill-height d-flex align-center>
              <v-icon
                color="grey darken-1"
                :style="{'line-height':'2px', 'font-size':iconSize+'px'}"
              >{{icon}}</v-icon>
          </v-flex>

          <div class="pos-rel" :style="{height:titleHeight+'px'}">
            <div class="grey--text text--darken-1 pos-abs w-100" :style="titleStyle">
              {{title}}
            </div>
          </div>

        </v-layout>

      </div>
    </button>
  </div>

</template>

<script>
export default {
  name:'builder-actions-button',
  props: {
    title:{type:String, default:'title'},
    icon:{type:String, default:'create'},
    event:{type:String, default:'event'}
  },
  data() {
    return {
      blockHeight:80,
      titleHeight: 30,
      titleLineHeight: 12,
      iconSizeBase:40,
      iconSizeOffset:0,
      fontSizeOffset:0
    }
  },
  computed: {

    buttonStyle() {

      return {
        'padding-top'   : Math.floor(this.blockHeight/2) + 'px',
        'padding-bottom': Math.floor(this.blockHeight/2) + 'px',
        'width'         : this.blockWidth + 'px'
      }

    },

    titleStyle() {
      return {
        'font-weight':500,
        'font-size':this.fontSize+'px',
        'bottom':'10px',
        'line-height':this.titleLineHeight+'px'
      }
    },

    iconSize() {
      return this.iconSizeBase + this.iconSizeOffset;
    },

    blockWidth() {

      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 80
        case 'sm': return 100
        case 'md': return 100
        case 'lg': return 100
        case 'xl': return 100
      }

    },

    fontSize() {

      if(this.title.length < 10) {
        return 14 + this.fontSizeOffset
      }else{

        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12 + this.fontSizeOffset
          case 'sm': return 14 + this.fontSizeOffset
          case 'md': return 14 + this.fontSizeOffset
          case 'lg': return 14 + this.fontSizeOffset
          case 'xl': return 14 + this.fontSizeOffset
        }

      }

    }

  }
}
</script>

<style scoped>

.actions-button-button {
  width: 100px;
  padding-top: 40px;
  padding-bottom:40px;
}

</style>
