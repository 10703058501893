<template>

  <div class="box-color">

    <div class="abs-top-left pa-0_5 grey--text">
      {{label}}
    </div>
    
    <color-blocks
      :colors="colors"
      :value="value"
      @input="(color) => $emit('input', color)"
      :padding-top="30"
    ></color-blocks>

    <v-layout align-center class="border-top pa-1 white lighten-2">
      <v-btn color="grey darken-1" dark @click="$emit('cancel')">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="$emit('submit')">Ok</v-btn>
    </v-layout>

  </div>

</template>

<script>
import ColorBlocks from '@/components/customizer/ColorBlocks.vue'

export default {
  name:'box-color',
  components: {
    ColorBlocks,
  },
  props: {
    value: {type:String, default:null},
    colors : {type:Array, default:undefined},
    label: {type:String, default:null},
  },
  data() {
    return {
    }
  },
  // mounted() {
  //   console.log('MOUNTED!!!!');
  // },
  // beforeDestroy() {
  //   console.log('DESTROYED!!!!');
  // },
  methods: {
  },
}
</script>
