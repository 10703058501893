<template>

  <!--
  NOTE: (Tom) I hacked this to maintain a consistent height ~ to prevent the awful "no animation"
        See that style.height has the (value || true) ~ that is the hack
        ^
        This also meant that the bottom sheets needed z-index management
  -->

  <div
    class="my-bottom-sheet"
    :class="[colorClassContainer]"
    :style="{
      'bottom': (value) ? offsetBottom+'px' : offsetBottom-height+'px',
      'height': (value || true) ? height+'px' : 0,
      'z-index': zIndexComputed,
    }"
  >

    <div
      class="bottom-sheet-layout-container"
      :class="[colorClassContainer]"
      :style="{'height':height+'px'}"
      ref="container"
    >

      <v-layout justify-center style="height:100%">
        <v-flex sm11 md9 lg8>
          <div
            class="bottom-sheet-content-container"
            :class="[colorClassContent]"
            :style="[fullScreen?{'height':'100%'}:{}]"
            ref="content"
          >
            <slot></slot>
          </div>
        </v-flex>
      </v-layout>

      <!-- NOTE: Scrolling containers should be handled externally. -->

    </div>

  </div>

</template>

<script>
export default {
  name:'my-bottom-sheet',
  components: {
  },
  props: {
    colorClassContainer:{type:String, default:'grey lighten-4'},
    colorClassContent:{type:String, default:'white'},

    fullScreen:{type:Boolean, default:false},
    offsetTop:{type:Number, default:28},      // offset from top in pixels
    offsetBottom:{type:Number, default:0},    // offset from bottom in pixels

    top:{type:Boolean, default:true},         // if needs to be on top (z-index)
    disableZIndex: { type:Boolean, default: false },

    value: { type:Boolean, default:false },  // active
  },
  data() {
    return {
      height: window.innerHeight,
    };
  },
  computed: {
    zIndexComputed() {
      if(this.disableZIndex) { return; }
      return (this.top) ? 450 : (this.value) ? 250 : 240;
    },
  },
  mounted() { this.updateElement(); },
  updated() { this.updateElement(); },
  methods: {

    updateElement() {

      this.$nextTick(() => {
      this.$nextTick(() => {

        // height transitions
        let maxHeight = window.innerHeight - this.offsetTop - this.offsetBottom;
        let contentHeight = this.$refs.content.offsetHeight;

        // set height
        if(this.fullScreen || contentHeight >= maxHeight) { contentHeight = maxHeight; }
        this.height = contentHeight;

        // emit changes in height when active
        if(this.value) {
          this.$emit('update', this.height + this.offsetBottom);
        }

      });
      });

    },

  }
}

</script>

<style scoped>

.my-bottom-sheet {
  position: fixed;
  left: 0;
  right: 0;
  overflow: hidden;

  -webkit-transition: height 0.3s cubic-bezier(.25,.8,.50,1);
  -moz-transition: height 0.3s cubic-bezier(.25,.8,.50,1);
  -o-transition: height 0.3s cubic-bezier(.25,.8,.50,1);
  transition: height 0.3s cubic-bezier(.25,.8,.50,1);

  /* NEW */
  -webkit-transition: bottom 0.3s cubic-bezier(.25,.8,.50,1);
  -moz-transition: bottom 0.3s cubic-bezier(.25,.8,.50,1);
  -o-transition: bottom 0.3s cubic-bezier(.25,.8,.50,1);
  transition: bottom 0.3s cubic-bezier(.25,.8,.50,1);

}

/* transition */
.bottom-sheet-layout-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

</style>
