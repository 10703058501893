<template>

  <div class="customizer">

    <div class="customizer" :style="{'background-color': backgroundColor}">

      <!-- **************************************** -->
      <!-- Main Content  -->

      <div
        ref="B3ContainerContainer"
        class="B3-container-container fades"
        style="opacity:0;"
        :style="{'bottom':b3BottomOffset+'px'}"
      >

        <!-- B3 container -->
        <div
          ref="B3Container"
          class="abs-container"
          id="B3-container"
        ></div>

        <!-- Drawer Nav Trigger -->
        <div class="pos-abs">
          <v-btn dark icon @click="drawer=!drawer">
          <v-icon>menu</v-icon>
          </v-btn>
        </div>

        <!-- Testing -->
        <div class="pos-abs text-xs-center" style="left:0;" >
          <!-- <v-btn class="mr-5" to="/">Home</v-btn> -->
          <!--
          <v-btn color="info" @click="showBuilder=!showBuilder">Builder</v-btn>
          <v-btn color="error" @click="incCurrentTab(-1)">-</v-btn>
          <v-btn color="warning" @click="incCurrentTab(1)">+</v-btn>
          -->
        </div>

        <transition name="fade">
          <div v-if="doneButtonProcessing" class="pos-abs" style="bottom:100px; left:20px; right:20px;">
            <v-progress-linear
              v-model="doneLoaderValue"
              class="elevation-13"
              height="16"
            ></v-progress-linear>
            <div class="text-xs-center white--text pt-3">
              <span style="text-shadow: 0 0 15px #000000;">{{doneLoaderText}}</span><br />

              <div class="mt-3"></div>
              <div class="elevation-18" style="width:20px; margin:auto; margin-bottom:-4px;"></div>
              <v-progress-circular
                indeterminate
                :size="28"
                color="white"
              ></v-progress-circular>

            </div>
          </div>
        </transition>

        <iframe ref="B3Screenshot" src="/js/b3-screenshot.html" style="position:absolute; width:0px; height:0px; right:99999999px;"></iframe>

      </div>

      <!-- HERE - UX FIX ~ loading spinner -->
      <div
        v-if="showLoader"
        style="position:absolute; color:white; text-align:center; right: 50%; top: 50%; transform:translate(50%, -50%)"
      >
        <div class="loader"></div>
      </div>

      <!-- **************************************** -->

      <!-- HERE - step0 markup -->
      <!-- Gift Step -->
      <my-bottom-sheet v-model="step0" fullScreen :offsetBottom="0">

        <!--
        grow
        no-bar
        -->

        <v-tabs v-model="step0Tab" dark class="no-bar full-height-tabs">

          <v-tab v-for="n in 2" :key="n" ripple>{{n}}</v-tab>

          <v-tabs-items :touchless="true">

            <!-- Gift Information ~ Contains Dynamic Components ~ Depending on Content Type -->
            <v-tab-item :transition="_t" :reverse-transition="_t" :key="1">
              <gift-main @submit="giftInfoDone"></gift-main>
            </v-tab-item>

            <!-- Customization Mode -->
            <v-tab-item :transition="_t" :reverse-transition="_t" :key="2">
              <gift-customization-mode @submit="giftCustomizationModeSelected"></gift-customization-mode>
            </v-tab-item>

          </v-tabs-items>

        </v-tabs>

      </my-bottom-sheet>

      <!-- Box Step -->
      <my-bottom-sheet v-model="step1" disableZIndex :offsetBottom="navHeight" @update="(val) => b3BottomOffset=val">

          <v-tabs :value="step1Tab" @input="tabClicked" dark slider-color="yellow" class="no-bar">

            <v-tab v-for="n in 7" :key="n" ripple>{{n}}</v-tab>

            <v-tabs-items :touchless="true">

              <!-- Gift Wrap Type -->
              <v-tab-item :transition="_t" :reverse-transition="_t" :key="1">
                <box-wrap-type
                  @custom="boxWrapTypeSelected('custom')"
                  @default="boxWrapTypeSelected('default')"
                >
                </box-wrap-type>
              </v-tab-item>

              <!-- Gift Wrap Selection -->
              <v-tab-item :transition="_t" :reverse-transition="_t" :key="2">
                <div v-if="gift.customizationMode!=='personal'" class="pa-0_5 grey--text">Wrapping Paper</div>
                <box-wrap
                  :wraps="wrapsActive"
                  :wrap="gift.customization.wrap"
                  @update:wrap="giftWrapSelected"
                  @submit="flow(true)"
                  @cancel="flow(false)"
                ></box-wrap>
              </v-tab-item>

              <!-- Tile Size -->
              <v-tab-item :transition="_t" :reverse-transition="_t" :key="3">
                <box-wrap-repeat
                  :value="gift.customization.wrap.repeat"
                  @input="(val) => {
                    if(gift.customization.wrap.src)
                      gift.customization.wrap.repeat = val;
                  }"
                  @submit="flow(true)"
                  @cancel="flow(false)"
                >
                </box-wrap-repeat>
              </v-tab-item>

              <!-- Ribbon Color -->
              <v-tab-item :transition="_t" :reverse-transition="_t" :key="4">
                <box-color
                  v-model="gift.customization.ribbon"
                  label="Ribbon Color"
                  @submit="flow(true)"
                  @cancel="flow(false)"
                ></box-color>
              </v-tab-item>

              <!-- Background -->
              <v-tab-item :transition="_t" :reverse-transition="_t" :key="5">

                <v-tabs :value="step1Tab4Tab" @input="subTabClicked" :height="30" dark grow slider-color="yellow">
                  <v-tab :key="1" ripple>Color</v-tab>
                  <v-tab :key="2" ripple>Pattern</v-tab>

                  <v-tabs-items :touchless="true">

                    <!-- Color -->
                    <v-tab-item :transition="_t" :reverse-transition="_t" :key="1">
                      <box-color
                        v-model="gift.customization.color"
                        label="Background Color"
                        @submit="flow(true)"
                        @cancel="flow(false)"
                      ></box-color>
                    </v-tab-item>

                    <!-- Pattern -->
                    <v-tab-item :transition="_t" :reverse-transition="_t" :key="2">
                      <box-pattern
                        :images="textures"
                        :image.sync="gift.customization.texture.src"
                        :colacity="{
                          color: gift.customization.texture.color,
                          opacity: gift.customization.texture.opacity
                        }"
                        @update:colacity="(c) => {
                          gift.customization.texture.color = c.color;
                          gift.customization.texture.opacity = c.opacity;
                        }"
                        @submit="flow(true)"
                        @cancel="flow(false)"
                      ></box-pattern>
                    </v-tab-item>

                  </v-tabs-items>
                </v-tabs>

              </v-tab-item>

              <!-- Back Texture Selection -->
              <v-tab-item :transition="_t" :reverse-transition="_t" :key="6">

                <div v-if="step1" class="pos-abs pl-1 white--text">
                  <h1 class="body-1">Back Texture</h1>
                </div>

                <div class="abs-top-left pa-0_5 grey--text">
                  Vignette
                </div>

                <box-pattern
                  :images="backs"
                  :image.sync="gift.customization.back.src"
                  :colacity="{
                    color: gift.customization.back.color,
                    opacity: gift.customization.back.opacity
                  }"
                  @update:colacity="(c) => {
                    gift.customization.back.color = c.color;
                    gift.customization.back.opacity = c.opacity;
                  }"
                  :padding-top="30"
                  @submit="flow(true)"
                  @cancel="flow(false)"
                ></box-pattern>
              </v-tab-item>

              <!-- Front Texture Selection -->
              <v-tab-item :transition="_t" :reverse-transition="_t" :key="7">

                <div v-if="step1" class="pos-abs pl-1 white--text" style="top:-1.5em">
                  <h1 class="body-1">Front Texture</h1>
                </div>

                <div class="abs-top-left pa-0_5 grey--text">
                  Foreground
                </div>

                <box-pattern
                  :images="fronts"
                  :image.sync="gift.customization.front.src"
                  :colacity="{
                    color: gift.customization.front.color,
                    opacity: gift.customization.front.opacity
                  }"
                  @update:colacity="(c) => {
                    gift.customization.front.color = c.color;
                    gift.customization.front.opacity = c.opacity;
                  }"
                  :padding-top="30"
                  @submit="flow(true)"
                  @cancel="flow(false)"
                ></box-pattern>
              </v-tab-item>

            </v-tabs-items>

          </v-tabs>

      </my-bottom-sheet>

      <!-- Card Step -->
      <my-bottom-sheet v-model="step2" disableZIndex :offsetBottom="navHeight" @update="(val) => b3BottomOffset=val">
        <div class="pos-rel">

          <!--
          <div v-if="step2" class="pos-abs pl-1 white--text" style="top:-1.5em">
            <h1 class="body-1">Card Details</h1>
          </div>
          -->

          <v-tabs :value="step2Tab" @input="tabClicked" :height="30" dark grow slider-color="yellow" :class="{'no-bar':gift.customizationMode !== 'personal'}">

            <v-tab :key="1" ripple>Text</v-tab>
            <v-tab :key="2" ripple>Front Style</v-tab>
            <v-tab :key="3" ripple>Inside Style</v-tab>

            <v-tabs-items :touchless="true">

              <!-- Text -->
              <v-tab-item :transition="_t" :reverse-transition="_t" :key="1">
                <div v-if="gift.customizationMode!=='personal'" class="pa-0_5 grey--text">Card</div>
                <card-text
                  :front.sync="gift.customization.card.front.text"
                  :inside.sync="gift.customization.card.inside.text"
                  :signature.sync="gift.customization.card.signature.text"
                  @submit="flow(true)"
                  @cancel="flow(false)"
                ></card-text>
              </v-tab-item>

              <!-- Front Style -->
              <v-tab-item :transition="_t" :reverse-transition="_t" :key="2">

                <v-tabs :value="step2Tab1Tab" @input="subTabClicked" :height="30" dark grow slider-color="yellow">
                  <v-tab :key="1" ripple>Card</v-tab>
                  <v-tab :key="2" ripple>Text</v-tab>

                  <v-tabs-items :touchless="true">

                    <!-- Color -->
                    <v-tab-item :transition="_t" :reverse-transition="_t" :key="1">
                      <card-color
                        v-model="gift.customization.card.front.color"
                        @submit="flow(true)" @cancel="flow(false)"
                      ></card-color>
                    </v-tab-item>

                    <!-- Font -->
                    <v-tab-item :transition="_t" :reverse-transition="_t" :key="2">
                      <card-font
                        :font="gift.customization.card.front.fontFamily"
                        @update:font="(font) => {
                          gift.customization.card.front.fontFamily = font.family;
                          gift.customization.card.front.fontSize   = font.size;
                          gift.customization.card.front.lineHeight = font.lineHeight;
                        }"
                        :color.sync="gift.customization.card.front.fontColor"
                        @submit="flow(true)" @cancel="flow(false)"
                      ></card-font>
                    </v-tab-item>

                  </v-tabs-items>
                </v-tabs>

              </v-tab-item>

              <!-- Inside Style -->
              <v-tab-item :transition="_t" :reverse-transition="_t" :key="3">

                <v-tabs :value="step2Tab2Tab" @input="subTabClicked" :height="30" dark grow slider-color="yellow">
                  <v-tab :key="1" ripple>Card</v-tab>
                  <v-tab :key="2" ripple>Text</v-tab>

                  <v-tabs-items :touchless="true">

                    <!-- Color -->
                    <v-tab-item :transition="_t" :reverse-transition="_t" :key="1">
                      <card-color
                        v-model="gift.customization.card.inside.color"
                        @submit="flow(true)" @cancel="flow(false)"
                      ></card-color>
                    </v-tab-item>

                    <!-- Font -->
                    <v-tab-item :transition="_t" :reverse-transition="_t" :key="2">
                      <card-font
                        :font="gift.customization.card.inside.fontFamily"
                        @update:font="(font) => {
                          gift.customization.card.inside.fontFamily = font.family;
                          gift.customization.card.inside.fontSize   = font.fontSize;
                          gift.customization.card.inside.lineHeight = font.lineHeight;
                        }"
                        :color.sync="gift.customization.card.inside.fontColor"
                        @submit="flow(true)" @cancel="flow(false)"
                      ></card-font>
                    </v-tab-item>

                  </v-tabs-items>
                </v-tabs>

              </v-tab-item>

            </v-tabs-items>

          </v-tabs>
        </div>
      </my-bottom-sheet>

      <!-- Review Step -->
      <my-bottom-sheet v-model="step3" disableZIndex :offsetBottom="navHeight" @update="(val) => b3BottomOffset=val">

          <v-tabs v-model="step3Tab" dark slider-color="yellow" class="no-bar">

            <v-tab v-for="n in 1" :key="n" ripple>{{n}}</v-tab>

            <v-tabs-items :touchless="true">

              <v-tab-item :transition="_t" :reverse-transition="_t" :key="1">

                <div>

                  <v-container>
                    <div class="text-xs-center">
                      <h2 class="subheading">{{reviewStepContentLabel}}</h2>
                      <span v-if="reviewStepContentText && reviewStepContentUrl" class="headline blue-grey--text">
                        <a :href="reviewStepContentUrl" target="_blank">{{reviewStepContentText}}</a>
                      </span>
                      <span v-else class="headline blue-grey--text">
                        {{reviewStepContentText}}
                      </span>
                    </div>
                  </v-container>

                  <div class="pb-3">
                    <v-btn :disabled="doneButtonDisabled" class="huge" color="success" @click="done" style="display:block; margin:auto;">
                      <span v-if="!doneButtonProcessing">Done</span>
                      <span v-else>
                        <v-progress-circular
                          indeterminate
                          :size="28"
                          color="white"
                        ></v-progress-circular>
                      </span>
                    </v-btn>
                  </div>

                  <div v-show="captchaNeeded" class="text-xs-center pb-2">
                    <captcha id="customizer"></captcha>
                  </div>

                </div>
              </v-tab-item>

            </v-tabs-items>

          </v-tabs>

      </my-bottom-sheet>

      <!-- Gift Wrap Builder -->
      <my-bottom-sheet v-model="showBuilder" fullScreen>

        <v-tabs v-model="builderTab" dark class="no-bar full-height-tabs">

          <v-tab v-for="n in 3" :key="n" ripple>{{n}}</v-tab>

          <v-tabs-items :touchless="true">

            <!-- Builder Main Tab -->
            <v-tab-item :transition="_t" :reverse-transition="_t" :key="1">
              <builder-main
                @image="imageUploaded"
                :active="builderTab == 0 && showBuilder"
                :initData="gift.customization.builder"
                @exportData="builderExported"
                @submit="builderDone"
                @cancel="showBuilder=false"
                ref="builderMain"
              >
              </builder-main>
            </v-tab-item>

            <!-- Builder Crop Tab -->
            <v-tab-item :transition="_t" :reverse-transition="_t" :key="2">
              <builder-crop
                :image="uploadedImage"
                @region="selectRegion"
                @cancel="builderTab=0"
                @submit="regionSelected"
              >
              </builder-crop>
            </v-tab-item>

            <!-- Builder Region Tab -->
            <v-tab-item :transition="_t" :reverse-transition="_t" :key="3">
              <builder-region
                :image="croppedImage"
                @cancel="builderTab=1"
                @submit="regionSelected"
              >
              </builder-region>
            </v-tab-item>

          </v-tabs-items>

        </v-tabs>

      </my-bottom-sheet>

      <!-- **************************************** -->

      <!-- Steps Bar ~ v-if had errors inside vuetify -->
      <my-steps-bar v-show="mode != 'edit'" :value='step' @input="stepsBarClicked" :height="navHeight" :steps="steps"></my-steps-bar>
      <my-steps-bar v-show="mode == 'edit'" :value='step' @input="stepsBarClicked" :height="navHeight" :steps="stepsEdit"></my-steps-bar>

    </div>

    <!-- Drawer Nav -->
    <nav-drawer v-model="drawer"></nav-drawer>

  </div>

</template>

<script>

// OLD
// @unused
// global B3

import Gx3d from '@/libs/gx3d'
import gx3ds from '@/libs/gx3ds'

import { toGx3d } from '@/libs/gx3d-translator'

import waitWindowLoad from '@/misc/wait-window-load'

import {
  steps,
  stepsEdit,
  //B3_DEFAULTS,
  wraps,
  wrapsFree,
  textures,
  wrapTextures,
  backs,
  fronts,
  flowMapPersonal,
  flowMapPersonalNoTour,
  flowMapFree,
  flowMapFreeNoTour,
} from '@/static/customizer'

import heap from '@/libs/heap'

import customizerInterface from '@/mixins/store.customizer'
import B3Interface from '@/mixins/b3.customizer'  // B3-remove

import MyBottomSheet from '@/components/customizer/MyBottomSheet.vue'
//import BottomSheet from '@/components/customizer/BottomSheet.vue'
//import BottomSheetLifted from '@/components/customizer/BottomSheetLifted.vue'

import MyStepsBar from '@/components/customizer/MyStepsBar.vue'
//import StepsBar from '@/components/customizer/StepsBar.vue'

import GiftMain from '@/components/customizer/GiftMain.vue'
import GiftTest from '@/components/customizer/GiftTest.vue'
import GiftTangoCard from '@/components/customizer/GiftTangoCard.vue'
import GiftCustomizationMode from '@/components/customizer/GiftCustomizationMode.vue'

import BoxWrapType from '@/components/customizer/BoxWrapType.vue'
import BoxWrap from '@/components/customizer/BoxWrap.vue'
import BoxWrapRepeat from '@/components/customizer/BoxWrapRepeat.vue'
import BoxColor from '@/components/customizer/BoxColor.vue'
import BoxPattern from '@/components/customizer/BoxPattern.vue'

import CardText from '@/components/customizer/CardText.vue'
import CardColor from '@/components/customizer/CardColor.vue'
import CardFont from '@/components/customizer/CardFont.vue'

import BuilderMain from '@/components/customizer/BuilderMain.vue'
import BuilderCrop from '@/components/customizer/BuilderCrop.vue'
import BuilderRegion from '@/components/customizer/BuilderRegion.vue'

import BuilderActionsButton from '@/components/customizer/BuilderActionsButton.vue'

import Captcha from '@/components/misc/Captcha.vue'

import NavDrawer from '@/components/navs/NavDrawer.vue'

export default {
  name:'customizer',
  mixins: [customizerInterface, B3Interface],
  components: {
    BuilderMain,
    BuilderCrop,
    BuilderRegion,
    BuilderActionsButton,
    GiftMain,
    GiftTest,
    GiftTangoCard,
    GiftCustomizationMode,
    BoxWrapType,
    BoxWrap,
    BoxWrapRepeat,
    BoxColor,
    BoxPattern,
    CardText,
    CardColor,
    CardFont,
    MyBottomSheet,
    //BottomSheet,
    //BottomSheetLifted,
    MyStepsBar,
    //StepsBar,
    Captcha,
    NavDrawer,
  },
  props: {
  },
  data() {
    return {
      steps:steps,
      stepsEdit:stepsEdit,

      tour: true,

      step:null,
      tab:0,
      subTab:0,
      subsubTab:0,

      // bottom-sheet bindings
      step0: false,  // Gift
      step1: false,  // Box
      step2: false,  // Card
      step3: false,  // Review/Done

      // tabs bindings
      step0Tab:0,            // 0 giftInfo, 1 customizationType

      step1Tab:0,            // 0 wrapType, 1 wrapSelection, 2 tileSize, 3 background, 4 back, 5 front
      step1Tab4Tab:0,        //   3 background -- 0 color, 1 src/opacity

      step2Tab:0,            // 0 cardText, 1 front style, 2 back style
      step2Tab1Tab:0,        //   1 front -- 0 card, 1 text
      step2Tab2Tab:0,        //   2 front -- 0 card, 1 text

      step3Tab:0,            // review step ~ only one tab for now...

      // other sheets
      showBuilder: false,
      builderTab:0,

      // ************************ //

      uploadedImage : null, //'/me-cropped.jpg', //null, // tmp: placeholder
      croppedImage  : null, //'/me-cropped.jpg',  //null, // tmp: placeholder

      // ************************ //

      transitions:true,  // enable/disable tab transitions ~ note: can only toggle on button clicks ~ annoying

      doneButtonProcessing:false,
      doneButtonAttempts: 0,
      doneButtonMaxAttempts: 3,


      b3BottomOffset:56,
      b3LastStatePing:0,
      b3StatePingDelay:100,

      drawer:false,

      // UX FIX ~ loader while Gx3d is not yet attached
      showLoader: true,

    };
  },
  computed: {

    wraps:() => wraps,
    wrapsFree:() => wrapsFree,
    textures:() => textures,
    wrapTextures:() => wrapTextures,
    backs:() => backs,
    fronts:() => fronts,

    wrapsActive() {
      if(this.gift.customizationMode === 'personal') { return this.wraps; }
      else{ return this.wrapsFree; }
    },

    navHeight() {
      if(this.$vuetify.breakpoint.xsOnly) { return 56; }
      else                                { return 70; }
    },

    backgroundColor() {

      return '#202020';

      // DEBUG
      // console.log('this.gift.customization.color:');
      // console.log(this.gift.customization.color);

      // removed in favor of always being black...
      // if( this.customizationMode === 'personal' ) { return this.gift.customization.color || 'gray'; }
      // else { return B3_DEFAULTS.sceneBackgroundColor; }

    },

    reviewStepContentLabel() {
      if(this.gift) {
        switch (this.gift.contentType) {
          case 'tango-card': return 'Tango Card Amount';
          case 'pdf': return 'PDF File';
          case 'link': return 'Gift Link';
          case 'image': return 'Image File';
        }
      }
    },

    reviewStepContentText() {
      if(this.gift) {
        if(this.gift.contentInfo) {
          switch (this.gift.contentType) {
            case 'tango-card': return '$' + this.gift.contentInfo.amount;
            case       'link': return this.gift.contentInfo.url;
            case        'pdf':
            case      'image': return (this.gift.contentInfo.file) ? this.gift.contentInfo.file.name : null;
          }
        }
      }
      return null;
    },

    reviewStepContentUrl() {
      if(this.gift) {
        if(this.gift.contentInfo) {
          switch (this.gift.contentType) {
            case 'tango-card': return null;
            case 'pdf':   return (this.gift.contentInfo.file) ? this.gift.contentInfo.file.url : null;
            case 'link':  return this.gift.contentInfo.url;
            case 'image': return (this.gift.contentInfo.file) ? this.gift.contentInfo.file.url : null;
          }
        }
      }
      return null;
    },

    captchaNeeded() {
      return this.$store.getters.cartCaptchaNeededForAdd;
    },

    doneButtonDisabled() {
      return (this.doneButtonAttempts >= this.doneButtonMaxAttempts || ( this.captchaNeeded && !this.$store.getters.captchaToken('customizer')));
    },

    doneLoaderText() {
      return this.$store.getters.cartProgressText;
    },
    doneLoaderValue() {
      return this.$store.getters.cartProgressValue;
    },

    // tabs transitions wrapper
    _t() { return (this.transitions) ? undefined : null; },

  },
  watch: {

    // ------------------------------------------ //
    // navigation control

    step(value, prev) {
      // deactivate old & active new BottomSheet
      if('step' + prev in this) this['step' + prev]  = false;
      if('step' + value in this) this['step' + value] = true;
      this.updateStepTab(this.step, this.tab, this.subTab, this.subsubTab);
    },

    tab() {
      this.updateStepTab(this.step, this.tab, this.subTab, this.subsubTab);
    },

    subTab() {
      this.updateStepTab(this.step, this.tab, this.subTab, this.subsubTab);
    },

    subsubTab() {
      this.updateStepTab(this.step, this.tab, this.subTab, this.subsubTab);
    },

    navHeight() {
      this.b3BottomOffset=this.navHeight;
    }

  },
  async mounted() {
    // store.customizer.js ~ syncs 'gift' object

    // @deprecate
    // // override callbacks
    // B3.setLoadedCallback(function() {
    // }.bind(this));
    // B3.setFinishedCallback(function() {
    // }.bind(this));
    // this.syncB3();
    //
    // // start B3
    // B3.startInsideElement("B3-container");

    // OLD
    // console.log('gift after sync,');
    // console.log( JSON.stringify(this.gift,null,2) );

    // show loader
    this.showLoader = true;
    this.$refs.B3ContainerContainer.style.opacity = '0';

    // await window loaded
    await waitWindowLoad();

    // nextTicks used to prevent cropper error ~ perhaps cropper uses mounted function for inits
    // NOTE: there are two nextTicks for flexboxes to be up-to-date
    this.$nextTick(async () => {
      this.$nextTick(async () => {
        // await entire DOM loaded ~ not sure if this does anything
        this.$root.$nextTick(async () => {
          // delay to give DOM time to load
          setTimeout(async () => {

            // NEW
            // grab instance
            const gx3d = gx3ds.main.instance;
            await gx3d.onLoaded();
            // configure
            gx3d.reset();
            gx3d.setManualUpdate(false);
            gx3d.setSlowSpin(true);
            gx3d.startInsideElement('B3-container');
            gx3d.pushCustomizations(Gx3d.DEFAULT_CUSTOMIZATION);  // I think this is for card fonts
            gx3d.pushCustomizations(toGx3d({}));  // sets defaults
            if(this.gift) { gx3d.pushCustomizations(toGx3d(this.gift.customization)); }
            await gx3d.processCustomization();
            gx3d.reset();

            // FIX: was showing previous customization even though it shouldn't
            //      ^ added a timeout to let it update...
            setTimeout(async () => {

              gx3d.reset();
              this.$refs.B3ContainerContainer.style.opacity = '1';

              // hide loader
              this.showLoader = false;

            }, 700);

            // bottom offset
            this.b3BottomOffset = this.navHeight;

            // HERE - start customizer flow with animation
            // start
            setTimeout(() => {
              this.startFlowWithAnimation();
            }, 3000);

            ////////////////////////////////////////////////////////////////
            // tests

            // test - builder
            // this.showBuilder = true;

            // test - straight to step
            // this.go({step:0, tab:1, disable:true});

            ////////////////////////////////////////////////////////////////

          }, 80);
        });
      });
    });

    // debug
    window.c = this;
  },

  beforeDestroy() {
    // return instance
    const gx3d = gx3ds.main.instance;
    gx3d.startInsideElement(gx3ds.main.home);
    gx3d.setManualUpdate(true);
  },

  methods: {

    startFlowWithAnimation() {
      this.step0 = true;
      setTimeout(() => { this.flow(true, 'init'); }, 330);
    },

    // HERE - ping gx3d stage to update according to customization step
    // NOW: update to support gx3d
    async pingB3Stage() {

      const gx3d = gx3ds.main.instance;
      await gx3d.onLoaded();

      let now = Date.now();
      if(now - this.b3LastStatePing > this.b3StatePingDelay) {
        let stageCurrent = gx3d.getStage();

        if(this.step == 1 && this.tab == 0 && stageCurrent != gx3d.STAGE_RIBBON) {
          // B3.showStageRibbon();
          gx3d.gotoRibbon();
        }
        else
        if(this.step == 1 && (this.tab == 1 || this.tab == 2)) {
          // B3.showStageRibbon();
          gx3d.gotoRibbon();
        }
        else
        if(this.step == 1 && this.tab > 2 && stageCurrent != gx3d.STAGE_RIBBON) {
          // B3.showStageRibbon();
          gx3d.gotoRibbon();
        }
        else
        if(this.step == 2 && this.tab == 0 && stageCurrent != gx3d.STAGE_RIBBON) {
          // B3.showStageRibbon();
          gx3d.gotoRibbon();
        }
        else
        if(this.step == 2 && this.tab == 1 && stageCurrent != gx3d.STAGE_INSIDE) {
          // B3.showStageInside();
          gx3d.gotoInside();
        }
        else
        if(this.step == 2 && this.tab == 2 && stageCurrent != gx3d.STAGE_DONE) {
          // B3.showStageDone();
          gx3d.gotoDone();
        }
        else
        if(this.step == 3 && stageCurrent != gx3d.STAGE_RIBBON) {
          // B3.showStageRibbon();
          gx3d.gotoRibbon();
        }

        this.b3LastStatePing = now;
      }

    },

    // ------------------------------------------ //
    // navigation control

    flow(forward, stepClicked) {
      if( (stepClicked || stepClicked==0) && stepClicked != 'init' ) { this.tour = false }
      let step = this.step;
      let tab = this.tab;
      let subTab = this.subTab;

      if(this.gift.customizationMode === 'personal') {

        if(this.tour) {
          gotoNextFromMap(this, flowMapPersonal);
        }else{
          if( !gotoNextFromMap(this, flowMapPersonalNoTour) )
            gotoNextFromMap(this, flowMapPersonal);
        }

      }else{

        if(this.tour) {
          gotoNextFromMap(this, flowMapFree);
        }else{
          if( !gotoNextFromMap(this, flowMapFreeNoTour) )
            gotoNextFromMap(this, flowMapFree);
        }

      }

      ////////////////////////////////////////////////////////////////

      function gotoNextFromMap(me, map) {
        let o = map;

        if( stepClicked || stepClicked==0 ) {

          if( 'step' in o ) { o = o['step'];
            if( stepClicked in o ) { o = o[stepClicked];
              return me.go(o);
            }
          }

        }else{

          if(step in o) { o = o[step];
            if(tab in o) { o = o[tab];
              if(subTab in o) { o = o[subTab];
                return goDir(me, o);                              // goto step,tab,subTab
              }else{ return goDir(me, o); }                       // goto step,tab,subTab
            }  // do nothing...
          }    // do nothing...

        }

        function goDir(me, o) {
          if(forward) { if('next' in o) return me.go(o.next); else return false; }
          else        { if('prev' in o) return me.go(o.prev); else return false; }
        }

      }

      ////////////////////////////////////////////////////////////////

    },

    go(o) {
      if(o.disable) { this.transitions = false; } else { this.transitions = true; }
      let hasKey = false;
      if( 'step' in o )   { this.step = o.step; hasKey = true; }
      if( 'tab' in o )    { this.tab = o.tab; hasKey = true; }
      if( 'subTab' in o ) { this.subTab = o.subTab; hasKey = true; }
      return hasKey;
    },

    stepsBarClicked(step) {
      this.flow(true, step);
    },

    tabClicked(tab) {
      this.tab = tab;
      this.transitions = true;
    },

    subTabClicked(subTab) {
      this.subTab = subTab;
      this.transitions = true;
    },

    updateStepTab(step, tab, subTab, subsubTab) {
      // set step tab
      if( 'step' + step + 'Tab' in this )  {
        this['step' + step + 'Tab'] = tab;
      }
      // set step subTab
      if( 'step' + step + 'Tab' + tab + 'Tab' in this )  {
        this['step' + step + 'Tab' + tab + 'Tab'] = subTab;
      }
      // set step subsubTab
      if( 'step' + step + 'Tab' + tab + 'Tab' + tab + 'Tab' in this )  {
        this['step' + step + 'Tab' + tab + 'Tab' + tab + 'Tab'] = subsubTab;
      }

      // ping B3 (which now pings gx3d)
      this.pingB3Stage();
    },

    // ------------------------------------------ //
    // button/click hooks

    giftWrapSelected(wrap) {
      this.gift.customization.wrap = wrap;
      this.gift.customization.usingCustomWrap = false;
    },

    giftCustomizationModeSelected(mode) {
      // HERE: gift.customizationMode customization mode is set
      this.gift.customizationMode = mode;
      this.flow(true);
    },

    giftInfoDone(data) {
      this.gift.contentInfo = data.contentInfo;

      this.gift.deliveryType = data.deliveryType;
      this.gift.deliveryTime = data.deliveryTime;
      this.gift.deliveryTimezone = data.deliveryTimezone;

      this.gift.receiverEmail = data.receiverEmail;
      this.gift.receiverName = data.receiverName;
      this.gift.senderName = data.senderName;
      this.gift.anonymous = data.anonymous;

      this.flow(true);
    },

    boxWrapTypeSelected(mode) {
      if(mode === 'default') { this.flow(true); }
      else if(mode === 'custom') { this.showBuilder = true; }
    },

    done() {
      if(!this.doneButtonProcessing) {
        if(!this.doneButtonAttempts) {
          this.doneButtonAttempts = 1
        }
        else {
          this.doneButtonAttempts = this.doneButtonAttempts + 1
        }

        // Take a screenshot, then send image to server with checkout.
        let B3Screenshot = this.$refs.B3Screenshot.contentWindow;

        // promise chain...
        Promise.resolve()

        // take screenshots if available
        .then(() => {
          if(B3Screenshot && this.extractB3Settings) {
            emitStatus('Creating screenshot', 10, this.$store);

            B3Screenshot.setSettings( this.extractB3Settings() );

            // square screenshot
            return new Promise((resolve) => {
              B3Screenshot.start((dataURL) => { resolve(dataURL); });
            })
            .then((dataURL) => {
              // return heap.putData(dataURL, '_', '/gift/screenshot')
              // .then((blobUrl) => {
              //   this.gift.screenshot = blobUrl;
              //   console.log(blobUrl);  // future ~ use device pixel ratio
              // });

              this.gift.screenshot = heap.putData(dataURL, '_', '/gift/screenshot');
            })

            // wide screenshot ~ facebook
            .then(() => {
              return new Promise((resolve) => {
                B3Screenshot.again(1200, 940, 0.6632, 0.04, (dataURL) => { resolve(dataURL); });
              })
            })
            .then((dataURL) => {
              // return heap.putData(dataURL, '_', '/gift/screenshotWide')
              // .then((blobUrl) => {
              //   this.gift.screenshotWide = blobUrl;
              //   console.log(blobUrl);
              // });

              this.gift.screenshotWide = heap.putData(dataURL, '_', '/gift/screenshotWide');
            });

          }
          // log otherwise..
          console.log( 'Customizer: screenshot not available' );
        })

        // add to cart
        .then(() => this.$store.dispatch('addCustomizerStageToCart'))

        // done
        .then(() => {
          //console.log( 'DONE ADDING TO CART! GO TO CART PAGE!' );
          this.doneButtonProcessing = false;
          delete this.doneButtonAttempts
          this.$router.push('/cart');
        })
        .catch((error) => {
          let errorMessage = (error.response) ? error.response.data : error.message;
          switch (errorMessage) {
            case 'CAPTCHA_NEEDED':
              console.log('Captcha needed show bottom bar');
              this.$store.dispatch('showMessage', {type:'error', message:'Please verify that you are human.'});
              return;

              case 'CAPTCHA_FAILED':
                console.log('Captcha failed show bottom bar');
                this.$store.dispatch('showMessage', {type:'error', message:'Captcha failed.'});
                return;

              case 'MAX_TANGO_CARD_AMOUNT_EXCEEDED':
                console.log('Max tango card amount exceeded');
                this.$store.dispatch('showMessage', {type:'error', message:'Maximum Tango Card amount exceeded.'});
                return;

              case 'MIN_TANGO_CARD_AMOUNT_EXCEEDED':
                console.log('Min tango card amount exceeded');
                this.$store.dispatch('showMessage', {type:'error', message:'Minumum Tango Card amount not exceeded.'});
                return;

            default:
              if(this.doneButtonAttempts >= this.doneButtonMaxAttempts){
                this.$store.dispatch('showLongMessage', 
                  {type:'error', 
                  message:'We are very sorry, but something appear to be wrong. Please contact customer service', 
                  label: 'support@swipewrap.com',
                  href:'mailto:support@swipewrap.com'});
              }
              else {
                this.$store.dispatch('showMessage', {type:'error', message:error.message+' : Please try again in a few seconds'});
              }

              // Wait another 3 secconds for the message to display
              // before enabling the botton again
              setTimeout(() => {
                this.doneButtonProcessing = false
              }, 3000);

              console.log( error );
          }
        });

        this.doneButtonProcessing = true;
      }

    },

    // ------------------------------------------ //
    // builder hooks

    imageUploaded(imageInfo) {
      this.uploadedImage = imageInfo;
      this.builderTab = 1;
    },

    selectRegion(imageInfo) {
      this.croppedImage = imageInfo;
      this.builderTab = 2;
    },

    regionSelected(imageInfo) {
      // HERE - calls builderMain.addImage
      this.$refs.builderMain.addImage(imageInfo.src);
      this.builderTab = 0;
    },

    builderExported(data) {
      this.gift.customization.builder = data;
    },

    builderDone(data) {
      let dataURL = data.src;

      // update local builder data
      this.gift.customization.builder = data.data;

      // write to heap and set wrap
      heap.writeData(dataURL, '_', '/gift/personal/wrap/src')
      .then((blobUrl) => {

        // set local wrap src
        this.gift.customization.wrap.src = blobUrl;

        // flag that a custom wrap is being used
        this.gift.customization.usingCustomWrap = true;

        // set wrap.repeat if not already there
        if(!this.gift.customization.wrap.repeat)
          this.gift.customization.wrap.repeat = 8;

        // hide builder and continue flow..
        this.showBuilder = false;
        this.go({step:1, tab:2, disable:true});

      });

    },

    // ------------------------------------------ //
    // utils

    incCurrentTab(val) {
      this.transitions = true;
      if( this.showBuilder ) { this.builderTab += val}
      else{ this.tab += val; }
    },

  }
}

// util for emitting cart status
function emitStatus(status, percent, store) { store.dispatch('cartProgress', {text:status, value:percent}); }

</script>

<style>
.captcha > div {
  margin:auto !important;
}
</style>

<style scoped>

.v-btn.huge {
  font-size: 24px;
  height: 60px;
  padding-left:40px;
  padding-right:40px;
  border-radius: 8px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.customizer {
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.B3-container-container {
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  -webkit-transition: bottom 0.8s cubic-bezier(.25,.8,.50,1);
  -moz-transition: bottom 0.8s cubic-bezier(.25,.8,.50,1);
  -o-transition: bottom 0.8s cubic-bezier(.25,.8,.50,1);
  transition: bottom 0.8s cubic-bezier(.25,.8,.50,1);

}

.fades {
  -webkit-transition: opacity 0.3s cubic-bezier(.25,.8,.50,1);
  -moz-transition: opacity 0.3s cubic-bezier(.25,.8,.50,1);
  -o-transition: opacity 0.3s cubic-bezier(.25,.8,.50,1);
  transition: opacity 0.3s cubic-bezier(.25,.8,.50,1);
}

h1, h2 {
  font-weight: normal;
}

.loader {
  border: 16px solid #505050; /* Light grey */
  border-top: 16px solid #a0a0a0; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
