import fromString from '@/misc/from-string'

/**
 * two-way computed property shorthand for vuex store
 */
export function two(store, getter, setter, getterParams, setterParams, setterValueKey, isAction) {
  let get, set;

  // getter has parameters
  if( getterParams ) {
    get = function() { return store.getters[getter](getterParams); };
  }else{
    get = function() { return store.getters[getter]; };
  }

  // commit or dispatch depending on isAction
  let setterFunction = (isAction) ? 'dispatch' : 'commit';
  if( setterParams ) {
    set = function(value) { setterParams[setterValueKey] = value; store[setterFunction](setter, setterParams); };
  }else{
    set = function(value) { store[setterFunction](setter, value); };
  }

  return {get, set};
}

// two-way watcher for local variables
export function twoSync(a, b) {
  let watchers = {};
  watchers[a] = function(value) { fromString(this, b, value); };
  watchers[b] = function(value) { fromString(this, a, value); };
  return watchers;
}

// two-way conditional watcher for local variables ~ only syncs when condition is met
export function twoSyncIf(a, b, conditionFunction) {
  let watchers = {};
  watchers[a] = function(value) { if(conditionFunction(this)) { fromString(this, b, value); } };
  watchers[b] = function(value) { if(conditionFunction(this)) { fromString(this, a, value); } };
  return watchers;
}

// one-way conditional watcher for local variables
export function twoBind(a, b) {
  let watchers = {};
  watchers[a] = function(value) { fromString(this, b, value); };
  return watchers;
}

// one-way conditional watcher for local variables ~ sets property on all in list
export function twoBindAll(a, bList) {
  let watchers = {};
  watchers[a] = function(value) {
    for(let i = 0; i < bList.length; i++) {
      fromString(this, bList[i], value);
    }
  };
  return watchers;
}

// one-way conditional watcher for local variables ~ sets property from list depending on indexFunction
export function twoBindSelect(a, bList, indexFunction) {
  let watchers = {};
  watchers[a] = function(value) {
    var index = indexFunction(this);
    if( index != -1 ) {
      fromString(this, bList[index], value);
    }
  };
  return watchers;
}
