<template>
  <div class="card-text rel-container">

    <v-container class="pb-1">

      <v-layout>
        <v-flex xs11>
          <v-textarea
            :rows="2"
            no-resize
            label="Front"
            :placeholder="'Happy Birthday!'"
            :value="front"
            @change="(val) => { if(!val) val=' '; $emit('update:front', val); }"
          ></v-textarea>

        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs11>
          <v-textarea
            :rows="3"
            no-resize
            label="Inside"
            :placeholder="'You are the best!\nHave a nice day!!'"
            :value="inside"
            @change="(val) => { if(!val) val=' '; $emit('update:inside', val); }"
          ></v-textarea>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs6 sm4>
          <v-textarea
            :rows="2"
            no-resize
            label="Closing"
            :placeholder="'Sincerely,\nYou'"
            :value="signature"
            @change="(val) => { if(!val) val=' '; $emit('update:signature', val); }"
          ></v-textarea>
        </v-flex>
      </v-layout>

    </v-container>
    
    <v-layout align-center class="border-top pa-1 white">
      <!-- <v-btn color="grey darken-1" dark @click="$emit('cancel')">Back</v-btn> -->
      <v-spacer></v-spacer>
      <v-btn color="success" @click="$emit('submit')">Ok</v-btn>
    </v-layout>

  </div>
</template>

<script>
export default {
  name:'card-text',
  props: {
    front: {Type:String, default:null},
    inside: {Type:String, default:null},
    signature: {Type:String, default:null},
  }
}
</script>
