<template>

  <div class="card-font">

    <color-blocks
      :colors="colors"
      :value="color"
      @input="(color) => $emit('update:color', color)"
    ></color-blocks>


    <div
      class="font-family-blocks center-scroll-x "
      :style="{
        'padding-top':7+'px',
        'padding-bottom':7+'px',
      }"
    >
    
      <div v-for="(f, i) in fonts" :key="i"
        v-ripple
        class="font-family-block"
        :class="{'selected':f.family==font}"
        @click="() => $emit('update:font', f)"
      >
        <v-layout fill-height align-center justify-center>
          <div class="pos-rel w-100">
            <span class="font-family-block-text unselectable"
              :style="{
                'font-family':f.family,
                'font-size':Math.round(18*f.size)+'px',
              }"
            >{{f.sample}}</span>
          </div>
        </v-layout>
      </div>

    </div>

    <v-layout align-center class="border-top pa-1 white lighten-2">
      <v-btn color="grey darken-1" dark @click="$emit('cancel')">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="$emit('submit')">Ok</v-btn>
    </v-layout>

  </div>

</template>

<script>
import {fonts} from '@/static/customizer'

import ColorBlocks from '@/components/customizer/ColorBlocks.vue'

export default {
  name:'card-font',
  components: {
    ColorBlocks,
  },
  props: {
    value: {type:String, default:null},

    color: {type:String, default:null},
    font: {type:String, default:null},

    colors : {type:Array, default:undefined},
  },
  data() {
    return {
    }
  },
  computed: {
    fonts: () => fonts,
  },
  methods: {
  },
}
</script>

<style scoped>

.font-family-blocks {
  padding-left:  5px;
  padding-right: 5px;
}

.font-family-block {
  display: inline-block;
  width:  90px;
  height: 38px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  margin-left:  3px;
  margin-right: 3px;
  cursor: pointer;
}
.font-family-block.selected {
  border-width: 2px;
  border-color: grey;
}

.font-family-block-text {
  position: absolute;
  line-height: 0;
  left: 0;
  right: 0;
  text-align: center;
}

</style>
