<template>

  <div class="gift-content">
    <component v-bind:is="component" ref="component" @getData="(data) => $emit('getData', data)"></component>
  </div>

</template>

<script>
import GiftPdf from '@/components/customizer/GiftPdf.vue'
import GiftImage from '@/components/customizer/GiftImage.vue'
import GiftTangoCard from '@/components/customizer/GiftTangoCard.vue'
import GiftLink from '@/components/customizer/GiftLink.vue'

export default {
  name:'gift-content',
  components: {
    GiftPdf,
    GiftImage,
    GiftTangoCard,
    GiftLink,
  },
  computed: {
    component() {
      switch (this.$store.getters.customizerContentType) {
        case 'pdf'        : return 'GiftPdf';
        case 'image'      : return 'GiftImage';
        case 'tango-card' : return 'GiftTangoCard';
        case 'link'       : return 'GiftLink';
        default           : return null;
      }
    },
  },
  methods: {
    getData() {
      this.$refs.component.getData();
    },
  }
}
</script>
