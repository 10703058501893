
let listening = false;
let resolved = false;
let resolve;
const promise = new Promise((r) => { resolve = r; });

function waitWindowLoad() {

  if(!resolved) {

    // window not loaded, add a listener...
    if (document.readyState !== 'complete') {
      if(!listening) {
        listening = true;
        window.addEventListener('load', () => {
          resolved = true;
          resolve();
        });
      }
    }

    // window loaded, just resolve
    else{
      resolved = true;
      resolve();
    }

  }

  return promise;
}

export default waitWindowLoad;
