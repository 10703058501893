<template>
  <div class="builder-canvas">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
/* global fabric */
import * as core from '@/misc/fabric.builder-canvas-core'

import {ITEM_SCREEN_RATIO} from '@/static/customizer'

export default {
  name: 'builder-canvas',
  data() {
    return {
      canvas:null,        // fabric canvas
      textureObj : null,  // fabric image object ~ need to hold onto reference
      canvasItems:[],     // core canvas items

      textureSrc : '/assets/wrap-textures/dots.png',
      textureOpacity : 0,
      textureColor : 'white',
      color : 'grey',

    }
  },
  computed: {
    initialized() {
      return this.canvas != null;
    }
  },
  watch: {

    color() {
      if(!this.initialized) return;
      core.setColor(this.color, this.canvas);
      core.renderCanvas(this.canvas);
    },

    textureSrc() {
      if(!this.initialized) return;
      core.setTexture(this.textureSrc, this.canvas, (textureObj) => {
        this.textureObj = textureObj;
        core.updateTexture(this.textureObj, this.canvas, {opacity:this.textureOpacity, color:this.textureColor});
        core.renderCanvas(this.canvas);
      });
    },

    textureOpacity() {
      if(!this.initialized || !this.textureObj) return;
      core.updateTexture(this.textureObj, this.canvas, {opacity:this.textureOpacity});
      core.renderCanvas(this.canvas);
    },

    textureColor() {
      if(!this.initialized || !this.textureObj) return;
      core.updateTexture(this.textureObj, this.canvas, {color:this.textureColor});
      core.renderCanvas(this.canvas);
    },

  },
  methods: {

    init(data) {
      let canvas = this.$refs.canvas;
      let container = canvas.parentNode.parentNode;
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
      this.canvas = new fabric.Canvas(canvas, {selection:false});
      core.initCanvas(this.canvas);

      if(data) {

        core.loadData(data, this.canvas, (textureObj, canvasItems) => {
          this.textureObj = textureObj;
          this.canvasItems = canvasItems;
          core.renderCanvas(this.canvas);
        });

      }else{

        core.setColor(this.color, this.canvas);

        if(this.textureSrc) {
          core.setTexture(this.textureSrc, this.canvas, (textureObj) => {
            this.textureObj = textureObj;
            core.updateTexture(this.textureObj, this.canvas, {opacity:this.textureOpacity, color:this.textureColor});
            core.renderCanvas(this.canvas);
          });
        }else{
          core.renderCanvas(this.canvas);
        }

      }

      console.log( 'FABRIC CANVAS INITIALIZED' );
    },

    add(src, cb) {
      core.addItem(src, this.canvas, this.canvasItems, ITEM_SCREEN_RATIO, null, cb);
    },

    exportData() {
      return core.exportData(this.canvas, this.canvasItems, ITEM_SCREEN_RATIO, {
        texture : { src: this.textureSrc, opacity: this.textureOpacity, color: this.textureColor },
        color   : this.color
      });
    },

    exportCanvas() {
      let dataURL = core.exportCanvas(this.canvas, {
        format:'jpeg',
        multiplier:512/this.canvas.width,
        quality:0.8  // compress it a bit. doesn't need to be perfect. :D
      });
      return dataURL;
    },

  }
}
</script>
