<template>
  <div class="builder-texture-canvas">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
/* global fabric */
import * as core from '@/misc/fabric.builder-canvas-core'

export default {
  name: 'builder-texture-canvas',
  props: {
    src : {type:String, default:null},
    opacity : {type:Number, default:0},
    color : {type:String, default:'white'},
    background : {type:String, default:'grey'},
  },
  data() {
    return {
      canvas:null,        // fabric canvas
      textureObj : null,  // fabric image object ~ need to hold onto reference
    }
  },
  computed: {
    initialized() {
      return this.canvas != null;
    }
  },
  watch: {

    background() {
      if(!this.initialized) return;
      core.setColor(this.background, this.canvas);
      core.renderCanvas(this.canvas);
    },

    src() {
      if(!this.initialized || !this.textureObj) return;
      core.setTexture(this.src, this.canvas, (textureObj) => {
        this.textureObj = textureObj;
        core.updateTexture(this.textureObj, this.canvas, {color:this.color, opacity:this.opacity});
        core.renderCanvas(this.canvas);
      });
    },

    opacity() {
      if(!this.initialized || !this.textureObj) return;
      core.updateTexture(this.textureObj, this.canvas, {opacity:this.opacity});
      core.renderCanvas(this.canvas);
    },

    color() {
      if(!this.initialized || !this.textureObj) return;
      core.updateTexture(this.textureObj, this.canvas, {color:this.color});
      core.renderCanvas(this.canvas);
    },

  },
  methods: {

    init() {
      let canvas = this.$refs.canvas;
      let container = canvas.parentNode.parentNode;
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
      this.canvas = new fabric.Canvas(canvas, {selection:false});
      core.initCanvas(this.canvas);

      core.setColor(this.background, this.canvas);
      core.setTexture(this.src, this.canvas, (textureObj) => {
        this.textureObj = textureObj;
        core.updateTexture(this.textureObj, this.canvas, {opacity:this.opacity, color:this.color});
        core.renderCanvas(this.canvas);
      });

      core.renderCanvas(this.canvas);
      console.log( 'FABRIC CANVAS INITIALIZED' );
    },

  }
}
</script>
