<template>

  <v-layout column fill-height>

    <!-- Main Content -->
    <v-flex class="pos-rel" fill-height>

      <!-- absolute container prevents twitching upon switching tabs.. dunno why -->
      <div class="abs-container">

        <!-- Test images: -->
        <!-- 'bomb_omb_retro.jpg' -->
        <!-- /test-image.png -->

        <!--
          :canMove="false"
          :fixedNumber="[1,1]"
          fixed
        -->
        <vueCropper
          ref="cropper"
          :img="(image) ? image.src : null"

          :info="false"

          autoCrop
          :autoCropHeight="autoCropSize"
          :autoCropWidth="autoCropSize"

          :outputSize="1"
          :outputType="'jpeg'"

          style="background-repeat:repeat;"
        ></vueCropper>

      </div>

    </v-flex>

    <!-- Actions Pane -->
    <v-flex>

      <div class="rel-container border-top">

        <div class="abs-top-left pa-0_25 grey--text text--lighten-1">
          Title
        </div>

        <div class="center-scroll-x">
          <builder-actions-button
            v-for="(item, i) in actions" :key="i"
            :title="item.title" :icon="item.icon" :event="item.event"
            @click="buttonPressed"
          >
          </builder-actions-button>
        </div>

      </div>

    </v-flex>

    <!-- Confirm Pane -->
    <v-flex>
      <v-layout fill-height align-center class="border-top pa-2 grey lighten-2">
        <v-btn color="grey darken-1" dark @click="$emit('cancel')">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="submit">Ok</v-btn>
      </v-layout>
    </v-flex>

  </v-layout>

</template>

<script>
import {builder as builderContent}  from '@/static/customizer'

import VueCropper from '@/components/misc/vue-cropper'
import BuilderActionsButton from './BuilderActionsButton.vue'

export default {
  name:'builder-crop',
  components: {
    VueCropper,
    BuilderActionsButton
  },
  props: {
    image: {type:Object, default:() => ({name: 'test.png', src:'/test-image.png'})}  //null, // '/bomb_omb_retro.jpg', // '/test-image.png',
  },
  data() {
    return {

      autoCropSize:100,
      cropperOutput:'/bomb_omb_retro.jpg',

      actions:builderContent.crop.actions
    }
  },
  watch: {
    image() {
      this.resetCropper();
    }
  },
  mounted() {

    this.$nextTick(() => {
      this.$nextTick(() => {  // additional tick for flexbox to load
        this.resetCropper();
      })
    })

  },
  methods: {

    resetCropper() {

      // Init Vue Cropper with proper sizing
      let cropper = this.$refs.cropper;

      // Compute desired crop box size
      let el = cropper.$el.parentNode;  // sizing element ~ container
      let w = el.offsetWidth;
      let h = el.offsetHeight;
      let percent = 0.8;

      // Set cropper initial size
      let size = (h < w) ? h*percent +10: w*percent +10;
      this.autoCropSize=size;

      // Reload cropper
      cropper.reload();

      // Previous reload attempts..
      //cropper.refresh();
      //cropper.goAutoCrop();

      // debug
      //console.log( 'CROPPER RESET' );
    },

    selectRegionClicked() {

      this.getCrop()
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        let imageInfo = {name:this.image.name, src:blobUrl};
        this.$emit('region', imageInfo);
      })

    },

    getCrop() {
      return new Promise(function(resolve) {
        this.$refs.cropper.getCropBlob((blob) => {
          resolve(blob);
        })
      }.bind(this));
    },

    getCropData() {
      return new Promise(function(resolve) {
        this.$refs.cropper.getCropData((blob) => {
          resolve(blob);
        })
      }.bind(this));
    },

    submit() {

      // HERE - BuilderCrop submit ~~~ the cropper exports a dataURL

      this.getCropData()
      .then((dataURL) => {
        let imageInfo = {name:this.image.name, src:dataURL};
        this.$emit('submit', imageInfo);
      })

    },

    buttonPressed(e) {

      switch(e) {
        case 'reset': this.resetCropper(); return;
        case 'region': this.selectRegionClicked(); return;

        default: console.log('NO EVENT HANDLER FOR ' + e + '. SEE buttonPressed() METHOD.');
      }

    }

  }
}
</script>
