<template>

  <div class="gift-main rel-container pa-1" style="overflow-y:auto;">

    <h1 class="body-2 pb-2">Gift Information</h1>

    <!-- Gift Content Information -->
    <div class="pos-rel pb-2">
      <gift-content ref="giftContent" @getData="getDataCbGiftContent"></gift-content>
      <div class="line-bottom"></div>
    </div>

    <!-- Delivery -->
    <div class="pos-rel pt-3">
      <h2 class="subheading">Delivery Method</h2>

      <!-- delivery methods -->
      <v-radio-group v-model="deliveryMethod" class="mt-1" hide-details>
        <v-radio
          v-for="(option, i) in deliveryOptions"
          :key="i"
          :value="option.value"
        >
          <div slot="label">
            <span class="delivery-method-option-label" :class="[ ((deliveryMethod == option.value) && 'active') ]">
              {{option.title}}
            </span>
            <br />
            <span class="caption delivery-method-option-caption grey--text text--lighten-1" :class="[ ((deliveryMethod == option.value) && 'active') ]">{{option.caption}}</span>
          </div>
        </v-radio>
      </v-radio-group>

      <!-- time pickers -->
      <div v-if="deliveryMethod === 'email'">

        <span class="grey--text text--darken-1">Deliver right away? <span class="caption">(Delivery can be scheduled otherwise)</span></span>
        <v-switch
          :label="((deliverRightAway) ? 'Yes' : 'No')"
          v-model="deliverRightAway"
          class="mt-1"
        ></v-switch>

        <v-container v-if="!deliverRightAway">

          <!-- date -->
          <v-menu
            :close-on-content-click="true"
            v-model="dateMenu"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="computedDateFormattedMomentjs"
              label="Delivery Date"
              prepend-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker
              v-model="date"
              :min="dateMin"
              :max="dateMax"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>

          <!-- time -->
          <v-menu
            ref="menu"
            :close-on-content-click="false"
            v-model="timeMenu"
            :nudge-right="40"
            :return-value.sync="time"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            max-width="290px"
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="time"
              label="Delivery Time (Please allow up to 30 minutes for gift to deliver)"
              prepend-icon="access_time"
              readonly
            ></v-text-field>
            <v-time-picker
              v-if="timeMenu"
              v-model="time"
              full-width
              format="24hr"
              @change="$refs.menu.save(time)"
            >
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="timeMenu = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.menu.save(time)">OK</v-btn>
            </v-time-picker>

          </v-menu>

          <!-- timezone -->
          <v-select
            :items="timezones"
            v-model="timezone"
            :menu-props="{ maxHeight: '400' }"
            label="Timezone"
            prepend-icon="access_time"
          ></v-select>

        </v-container>

      </div>

      <div class="line-bottom"></div>
    </div>

    <!-- Emails/Names -->
    <div class="pos-rel pt-3">
      <h2 class="subheading">Details</h2>

      <v-container>

        <v-text-field
          :label="'Recipient Email ' + ( (deliveryMethod === 'email') ? '(Required)' : '(Optional)' )"
          placeholder="mary.green@gmail.com"
          v-model="receiverEmail"
          :rules="[rules.email]"
        ></v-text-field>

        <v-text-field
          label="Recipient Name on Gift Email (Optional)"
          placeholder="Mary"
          v-model="receiverName"
        ></v-text-field>

        <v-text-field
          label="Your Name on Gift Email (Optional)"
          placeholder="Mary"
          class="mt-3"
          v-model="senderName"
        ></v-text-field>

        <span class="grey--text text--darken-1">Show your name to recipient? <span class="caption">(Gift will be anonymous otherwise)</span></span>
        <v-switch
          :label="((notAnonymous) ? 'Yes' : 'No')"
          v-model="notAnonymous"
          class="mt-1"
        ></v-switch>

      </v-container>

    </div>

    <div v-if="errorMessage">
      <v-alert :value="true" type="error"> {{errorMessage}} </v-alert>
    </div>

    <v-layout justify-center>

      <v-btn color="success" large @click="done">
        <span v-if="!doneButtonProcessing">Okay</span>
        <span v-else>
          <v-progress-circular
            indeterminate
            :size="28"
            color="white"
          ></v-progress-circular>
        </span>
      </v-btn>

    </v-layout>



  </div>

</template>

<script>
  import {deliveryOptions, timezones} from '@/static/customizer'
  import GiftContent from '@/components/customizer/GiftContent.vue'

  import moment from 'moment-timezone'

  export default {
    name:'gift-main',
    components: {
      GiftContent
    },
    data() {
      let now = moment();
      let giftDeliveryTime = this.$store.getters.customizerStageProperty('deliveryTime');
      return {
        doneButtonProcessing:false,

        receiverEmail:this.$store.getters.customizerStageProperty('receiverEmail'),
        receiverName:this.$store.getters.customizerStageProperty('receiverName'),
        senderName:this.$store.getters.customizerStageProperty('senderName'),
        notAnonymous:(this.$store.getters.customizerStageProperty('anonymous') == false),

        deliveryMethod:this.$store.getters.customizerStageProperty('deliveryType'),

        deliveryTime:giftDeliveryTime || now.format(),

        date:moment(giftDeliveryTime || now.format()).format('YYYY-MM-DD') || now.format('YYYY-MM-DD'),
        dateMin:now.format('YYYY-MM-DD'),
        dateMax:now.add(3,'M').format('YYYY-MM-DD'),
        dateMenu:false,

        time: moment((giftDeliveryTime || '2000-01-01T00:00')).format('HH:mm'),
        timezone: this.$store.getters.customizerStageProperty('deliveryTimezone') || 'America/Los_Angeles',
        timeMenu:false,
        deliverRightAway: !this.$store.getters.customizerStageProperty('deliveryTime'),

        errorMessage:null,

        rules: {
          required: value => !!value || 'Required.',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if( !value ) { return true; }  // allow blank
            return pattern.test(value) || 'Invalid e-mail.'
          }
        },

        data:{},  // data sent to Customizer
        contentInfoDataDone:false,

      }
    },
    computed: {

      deliveryOptions() {
        return deliveryOptions;
      },

      computedDateFormattedMomentjs () {
        return this.date ? moment(this.date).format('dddd, MMMM Do YYYY') : '';
      },

      timezones() {
        return timezones;
      },

    },
    mounted() {
      //console.log( moment().tz("America/Los_Angeles").format() );
      //console.log( moment().add(3,'M').format('YYYY-MM-DD') );
    },
    methods: {

      showErrorMessage(msg) {
        this.errorMessage = msg;
        setTimeout(() => {
          this.errorMessage = null;
        }, 3500);
      },

      // done button clicked
      done() {

        // validation
        if(this.deliveryMethod === 'email' && !this.receiverEmail) {
          this.showErrorMessage('Recipient Email Required (or just use "Link" delivery! 🙂)');
          return;
        }

        if(!this.doneButtonProcessing) {
          this.getData();
          this.doneButtonProcessing = true;
        }

      },

      getData() {
        // gift content
        this.$refs.giftContent.getData();

        // delivery method
        this.data.deliveryType = this.deliveryMethod;
        this.data.deliveryTimezone = this.timezone;
        this.data.deliveryTime = (this.deliverRightAway || this.deliveryMethod === 'link')
          // no scehduled delivery
          ? null
          // result is in ISO 8601 format ~ 2014-06-01T12:00:00-04:00
          : moment(this.date + 'T' + this.time, 'YYYY-MM-DD[T]HH:mm').tz(this.timezone).format()
        ;

        // emails/names
        this.data.receiverEmail = this.receiverEmail;
        this.data.receiverName = this.receiverName;
        this.data.senderName = this.senderName;
        this.data.anonymous = !this.notAnonymous;

      },

      // giftContent.getData callback
      getDataCbGiftContent(contentInfo) {
        this.data.contentInfo = contentInfo;
        this.contentInfoDataDone = true;
        this.pollDataDone();
      },

      // when each data piece is received
      pollDataDone() {
        this.$nextTick(() => {
          if(this.contentInfoDataDone /* && all others */) {
            this.doneButtonProcessing = false;
            this.dataDone();
          }
        });
      },
      dataDone() {
        this.$emit('submit', this.data);
      },

    }
  }

</script>

<style scoped>
  .delivery-method-option-label.active {
    color: #505050 !important;
  }
  .delivery-method-option-caption.active {
    color: #909090 !important;
  }
</style>
