<template>
<div class="gift-link">

  <h2 class="subheading">
    Link
    <!-- <span style="font-size:0.8em">(or swipewrap only)</span> -->
  </h2>

  <div class="pt-2" style="text-align:center;">

    <div class="grey--text text--darken-1">
      Enter link you would like to Share
    </div>
    <div class="grey--text mt-1">
      <b>NOTE:</b> Leave this blank if you want to send only the card wrapped in swipewrap
    </div>
    <div class="px-4">
      <v-text-field solo :value="url" @change="(val) => {url=clean(val);}"></v-text-field>
    </div>

  </div>

</div>
</template>

<script>
export default {
  name: 'gift-link',
  data() {
    return {
      url: this.$store.getters.customizerStageProperty('contentInfo.url'),
    }
  },
  methods: {

    clean(link) {

      // remove spaces
      link = link.replace(/\s+/g, '');

      // add http to it
      if (!hasURLstart(link)) {
        link = "https://" + link;
      }

      // add .com if not there
      if (link.indexOf('.') < 0) {
        link = link + '.com';
      }

      return link;

      // utility function
      function hasURLstart(link) {
        return (link.startsWith("http:") || link.startsWith("https:") || link.startsWith("ftp:") || link.startsWith("rtsp:") || link.startsWith("mms:"));
      }

    },

    getData() {
      this.$emit('getData', { url: this.url });
    },

  }

}
</script>
