import { B3_DEFAULTS } from '@/static/customizer'

// NOTE: These are NOT in-place algorithms ~ these functions return new objects
//       ^
//       The passed in customization object could potentially be a
//       reactive varaible that is part of the UI, and we don't want to
//       trigger UI updates when calling this function

// NOTE: gx3d comes with a default gx3d.customization object already on it

export function toGx3d(customization, customizationMode) {
  if(!customization) { return {}; }

  const c = clone(customization);

  ////////////////////////////////////////////////////////////////
  // defaults support

  // NOW: fallback on defaults defined in this app when translating to gx3d

  if(c.wrap == null) { c.wrap = {}; }
  if(c.wrap.src == null) { c.wrap.src = B3_DEFAULTS.giftwrapTextureURL; }
  if(c.wrap.repeat == null) { c.wrap.repeat = B3_DEFAULTS.giftwrapTextureRepeat; }

  if(c.ribbon == null) { c.ribbon = B3_DEFAULTS.ribbonColor; }
  if(c.color == null) { c.color = B3_DEFAULTS.sceneBackgroundColor; }

  if(c.texture == null) { c.texture = {}; }
  if(c.texture.src == null) { c.texture.src = B3_DEFAULTS.sceneBackgroundImageURL; }
  if(c.texture.opacity == null || customizationMode === 'free') { c.texture.opacity = B3_DEFAULTS.sceneBackgroundImageOpacity; }

  if(c.back == null) { c.back = {}; }
  if(c.back.src == null) { c.back.src = B3_DEFAULTS.sceneBackImageURL; }
  if(c.back.opacity == null || customizationMode === 'free') { c.back.opacity = B3_DEFAULTS.sceneBackImageOpacity; }

  if(c.card == null) { c.card = {}; }

  if(c.card.front == null) { c.card.front = {}; }
  if(c.card.front.color == null) { c.card.front.color = B3_DEFAULTS.cardDetails.frontCoverBackgroundColor; }
  if(c.card.front.fontColor == null) { c.card.front.fontColor = B3_DEFAULTS.cardDetails.textContent.title.color; }
  if(c.card.front.fontFamily == null) { c.card.front.fontFamily = B3_DEFAULTS.cardDetails.textContent.title.fontFamily; }

  if(c.card.inside == null) { c.card.inside = {}; }
  if(c.card.inside.color == null) { c.card.inside.color = B3_DEFAULTS.cardDetails.insideLeftBackgroundColor; }
  if(c.card.inside.fontColor == null) { c.card.inside.fontColor = B3_DEFAULTS.cardDetails.textContent.inside.color; }
  if(c.card.inside.fontFamily == null) { c.card.inside.fontFamily = B3_DEFAULTS.cardDetails.textContent.inside.fontFamily; }

  if(c.card.signature == null) { c.card.signature = {}; }
  if(c.card.signature.fontColor == null) { c.card.signature.fontColor = B3_DEFAULTS.cardDetails.textContent.signature.color; }
  if(c.card.signature.fontFamily == null) { c.card.signature.fontFamily = B3_DEFAULTS.cardDetails.textContent.signature.fontFamily; }

  ////////////////////////////////////////////////////////////////

  // ribbon
  if(c.ribbon && typeof c.ribbon === 'string') {
    const color = c.ribbon;
    c.ribbon = { color };
  }

  // background color
  if(c.color && typeof c.color === 'string') {
    if(!(c.background && typeof c.background === 'object')) { c.background = {}; }
    c.background.color = c.color;
  }

  // background texture
  if(c.texture && typeof c.texture === 'object') {
    if(!(c.background && typeof c.background === 'object')) { c.background = {}; }
    if('src' in c.texture) { c.background.src = c.texture.src; }
    if('opacity' in c.texture) { c.background.opacity = c.texture.opacity; }
  }

  // card
  if(c.card) {

    // front
    if(c.card.front) {
      const side = c.card.front;
      convertCardSideText(side, 95);
    }

    // back
    if(c.card.back) {
      const side = c.card.back;
      convertCardSideText(side, 36);
    }

    // inside
    if(c.card.inside) {
      const side = c.card.inside;
      convertCardSideText(side, 90);
    }

    // signature
    if(c.card.signature) {
      const side = c.card.signature;     // b3 side
      const targetSide = c.card.inside;  // gx3d side

      const text = side.text;
      if(text && typeof text === 'string') {
        const textString = text;
        targetSide.signature = {
          text: textString,
          color: targetSide.fontColor,  // side.fontColor
          fontFamily:'Gloria Hallelujah',  //  side.fontFamily
          fontSize: (side.fontSize || 1) * 45,
          lineHeight: side.lineHeight,
        };
      }

    }

  }

  ////////////////////////////////////////////////////////////////

  function convertCardSideText(side, fontSizeBasis) {
    const text = side.text;
    if(text && typeof text === 'string') {
      const textString = text;
      side.text = {
        text: textString,
        color: side.fontColor,
        fontFamily: side.fontFamily,
        fontSize: (side.fontSize || 1) * (fontSizeBasis || 1),
        lineHeight: side.lineHeight,
      };
    }
  }

  ////////////////////////////////////////////////////////////////

  return c;
}

export function fromGx3d(customization) {
  // LATER: fromGx3d ~ convert Gx3d schema into old schema
  // NOTE: is this even necessary?
}

// debug
if(process.env.VUE_APP_DEBUG_LOG === 'true') {
  window.toGx3d = toGx3d;
}

////////////////////////////////////////////////////////////////
// utils

// NOTE: using this clone function because it's better than the one in @/misc/
/*
 * clone
 * deep clone an object
 *
 * works with: objects, arrays, objects with arrays, arrays with objects
 * o ~ reference object
 * t ~ target object (optional)
 *
 * returns target if passed
 * returns clone if no target is passed
 */
function clone(o, t) {
  if(!t) { t = (o instanceof Array) ? [] : {}; }
  for(let k in o) {
    if(o[k] && o[k] instanceof Array) {
      if(!(t[k] && t[k] instanceof Array)) { t[k] = []; }
      clone(o[k], t[k]);
    }
    else if(o[k] && typeof o[k] === 'object') {
      if(!(t[k] && typeof t[k] === 'object')) { t[k] = {}; }
      clone(o[k], t[k]);
    }
    else{
      t[k] = o[k];
    }
  }
  return t;
}
