<template>

  <div class="box-wrap-repeat">

    <!--
    v-model="slider"
    :value="value"
    @end="(val) => slider=val"
    -->

    <div class="abs-top-left pa-0_5 grey--text">
      Tile Size
    </div>

    <div class="px-4 pb-1 pt-4">
      <v-slider
        :hide-details="true"
        thumb-color="red"
        :value="value"
        @input="(val) => $emit('input', Math.round(val))"
        always-dirty
        :min="2"
        :max="16"
      ></v-slider>
    </div>

    <v-layout align-center class="border-top pa-1 white">
      <v-btn color="grey darken-1" dark @click="$emit('cancel')">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="$emit('submit')">Ok</v-btn>
    </v-layout>

  </div>

</template>

<script>

export default {
  name:'box-wrap-repeat',
  props: {
    value: {type:Number, default:null},
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>

.v-input--slider {
  margin: 0;
}

</style>
