<template>

  <v-layout column fill-height>

    <!-- Main Content -->
    <v-flex fill-height>

      <v-layout fill-height align-center justify-center>

        <!-- Region Selector  -->
        <div class="canvas-container pos-rel" style="background-color:red;" ref="canvasContainer">
          <canvas class="pos-abs" ref="canvas"></canvas>
          <img :src="(image) ? image.src : null" ref="image"/>
        </div>

      </v-layout>

    </v-flex>

    <!-- Actions Pane -->
    <v-flex>

      <div class="rel-container border-top">

        <div class="abs-top-left pa-0_25 grey--text text--lighten-1">
          Title
        </div>

        <div class="center-scroll-x">

          <builder-actions-button
            v-for="(item, i) in actions" :key="i"
            :title="item.title" :icon="item.icon" :event="item.event"
            @click="buttonPressed"
            :ref="'actions-button-'+item.event"
          >
          </builder-actions-button>

        </div>

      </div>

    </v-flex>

    <!-- Confirm Pane -->
    <v-flex>
      <v-layout fill-height align-center class="border-top pa-2 grey lighten-2">
        <v-btn color="grey darken-1" dark @click="$emit('cancel')">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="exportRegion">Ok</v-btn>
      </v-layout>
    </v-flex>

  </v-layout>

</template>

<script>
import {builder as builderContent}  from '@/static/customizer'

import exportRegion from '@/misc/export-region'

import BuilderActionsButton from './BuilderActionsButton.vue'

export default {
  name:'builder-region',
  props:{
    image:{type:Object, default:null},
  },
  components: {
    BuilderActionsButton
  },
  data() {
    return {
      actions:builderContent.region.actions,
      size:100,
      sizeLogical:300,
      sizeAxis:true,  // true ~ width, false ~ height
      sizeAspect:1,

      brushSizes:[10,20,40,80],
      brushSizeIconOffsets:[0,10,20,30],
      brushSizeIndex:0,
      brushSize:10,
      brushSizeIconOffset:0,

      ctx:null,  // canvas context

      alpha:0.6,

      lastPoint:null,
      isDrawing:false,

      undoStack:[],
      undoStep:-1,

      paddingX:32,
      paddingY:32
    }
  },
  computed: {
    sizeRatio() { return this.sizeLogical/this.size },
    radiusOuter() { return Math.round(this.brushSize * this.sizeRatio) },
    radiusInner() { return Math.round( this.radiusOuter * 0.25) }
  },
  watch: {
    image() {
      if(this.image)
        this.refreshCanvas()
    }
  },
  mounted() {

    // get canvas context
    this.ctx = this.$refs.canvas.getContext('2d');

    // touch/mouse events
    this.$refs.canvas.onmousedown  = this.onDown;
    this.$refs.canvas.onmousemove  = this.onMove;
    this.$refs.canvas.onmouseup    = this.onUp;
    this.$refs.canvas.ontouchstart = this.onDown;
    this.$refs.canvas.ontouchmove  = this.onMove;
    this.$refs.canvas.ontouchend   = this.onUp;

    // size update not necessary on mount... doing it for conformity.
    this.$nextTick(() => {
    this.$nextTick(() => {  // extra tick for flex box loading
      if(this.image)
        this.refreshCanvas()
    })
    })

  },

  methods: {

    distanceBetween(point1, point2) {
      return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    },

    angleBetween(point1, point2) {
      return Math.atan2( point2.x - point1.x, point2.y - point1.y );
    },

    drawRadial(point) {
      var radgrad = this.ctx.createRadialGradient(
        point.x, point.y, this.radiusInner,
        point.x, point.y, this.radiusOuter
      );
      radgrad.addColorStop(0, 'rgba(1,1,1,1)');
      radgrad.addColorStop(1, 'rgba(1,1,1,0)');
      this.ctx.fillStyle = radgrad;
      this.ctx.fillRect(point.x-this.radiusOuter, point.y-this.radiusOuter, this.radiusOuter*2, this.radiusOuter*2);
    },

    getMousePos(e) {
      var rect = this.$refs.canvas.getBoundingClientRect();
      if( e.touches ) {
        return {
          x: (e.touches[0].clientX - rect.left)*this.sizeRatio,
          y: (e.touches[0].clientY - rect.top)*this.sizeRatio
        };
      }else{
        return {
          x: (e.clientX - rect.left)*this.sizeRatio,
          y: (e.clientY - rect.top)*this.sizeRatio
        };
      }

    },

    onDown(e) {
      e.preventDefault();

      var point = this.getMousePos(e);

      this.drawRadial(point);
      this.lastPoint = point;

      this.isDrawing = true;
    },

    onMove(e) {
      e.preventDefault();

      if (!this.isDrawing) {return;}

      var currentPoint = this.getMousePos(e);

      var dist  = this.distanceBetween(this.lastPoint, currentPoint);
      var angle = this.angleBetween(this.lastPoint, currentPoint);

      let x,y;
      for (var i = 0; i < dist; i+=2) {

        x = this.lastPoint.x + (Math.sin(angle) * i);
        y = this.lastPoint.y + (Math.cos(angle) * i);

        this.drawRadial({x, y});
      }

      this.lastPoint = currentPoint;
    },

    onUp(e) {
      e.preventDefault();

      this.isDrawing = false;
      this.pushUndo();
    },

    pushUndo() {
      this.undoStep++;
      if (this.undoStep < this.undoStack.length) { this.undoStack.length = this.undoStep; }
      this.undoStack.push(this.$refs.canvas.toDataURL());
    },

    undo() {
      if (this.undoStep > 0) {
        this.undoStep--;
        var canvasPic = new Image();
        canvasPic.src = this.undoStack[this.undoStep];
        canvasPic.onload = function () { this.refreshDraw(canvasPic); }.bind(this);
      }
    },

    redo() {
      if (this.undoStep < this.undoStack.length-1) {
        this.undoStep++;
        var canvasPic = new Image();
        canvasPic.src = this.undoStack[this.undoStep];
        canvasPic.onload = function () { this.refreshDraw(canvasPic); }.bind(this);
      }
    },

    refreshCanvas() {

      let container = this.$refs.canvasContainer;
      let canvas = this.$refs.canvas;
      let image = this.$refs.image;

      // parent node for sizing
      let parent = container.parentNode;
      let width = parent.offsetWidth - this.paddingX;
      let height = parent.offsetHeight  - this.paddingY;

      this.size = (width<=height) ? width : height;

      // img ~ for natural dimensions
      var img = new Image();
      img.src = this.image.src;
      img.onload = function() {
        //// sizing

        // div 0 prevention
        if( img.width <= 0 || img.height <= 0 ) { return; }

        // primary axis and aspect ratio
        this.sizeAxis   = (img.width >= img.height);
        this.sizeAspect = ( this.sizeAxis ) ? img.height / img.width : img.width / img.height  ;

        // set dimensions
        if( this.sizeAxis ) {

          this.sizeLogical = img.width;

          container.style.width  = this.size + 'px';
          container.style.height = this.size * (this.sizeAspect) + 'px';

          canvas.width  = this.sizeLogical;
          canvas.height = this.sizeLogical * (this.sizeAspect);

          canvas.style.width  = this.size + 'px';
          canvas.style.height = this.size * (this.sizeAspect) + 'px';

          image.style.width  = this.size + 'px';
          image.style.height = this.size * (this.sizeAspect) + 'px';

        }else{

          this.sizeLogical = img.height;

          container.style.width  = this.size * (this.sizeAspect) + 'px';
          container.style.height = this.size + 'px';

          canvas.width  = this.sizeLogical * (this.sizeAspect);
          canvas.height = this.sizeLogical;

          canvas.style.width  = this.size * (this.sizeAspect) + 'px';
          canvas.style.height = this.size + 'px';

          image.style.width  = this.size * (this.sizeAspect) + 'px';
          image.style.height = this.size + 'px';

        }

        //// graphics
        this.refreshDraw();
        this.pushUndo();

      }.bind(this);

    },

    refreshDraw(image) {
      // clear potential existing content
      this.ctx.fillStyle = 'rgba(255,255,255,1)';
      this.ctx.globalCompositeOperation = 'source-over';
      this.ctx.fillRect(0, 0, this.sizeLogical, this.sizeLogical);
      this.ctx.globalCompositeOperation = 'destination-out';
      this.ctx.fillRect(0, 0, this.sizeLogical, this.sizeLogical);

      // initial white box or optional image
      this.ctx.globalCompositeOperation = 'source-over';
      if(image) {
        this.ctx.drawImage(image, 0, 0);
      }else{
        this.ctx.fillStyle = 'rgba(255,255,255,' + this.alpha + ')';
        this.ctx.fillRect(0, 0, this.sizeLogical, this.sizeLogical);
      }

      // set all new art to be cut out
      this.ctx.globalCompositeOperation = 'destination-out';
    },

    exportRegion() {

      // HERE - BuilderRegion submit

      let dataURL = exportRegion(
        this.$refs.image,
        this.$refs.canvas,
        this.alpha
      );

      let imageInfo = {name:this.image.name, src:dataURL};
      this.$emit('submit', imageInfo);

    },

    sizeClicked() {
      let button = this.$refs['actions-button-size'][0];

      this.brushSizeIndex = ( this.brushSizeIndex + 1 ) % this.brushSizes.length;

      this.brushSize = this.brushSizes[ this.brushSizeIndex ];
      button.iconSizeOffset = this.brushSizeIconOffsets[ this.brushSizeIndex ];
    },

    buttonPressed(e) {

      switch(e) {
        case 'size': this.sizeClicked(); return;
        case 'undo': this.undo(); return;

        default: console.log('NO EVENT HANDLER FOR ' + e + '. SEE buttonPressed() METHOD.');
      }

    }

  }
}
</script>
