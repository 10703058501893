<template>

  <v-layout column>

    <v-flex xs12>
      <div class="pt-1 pl-1 grey--text text--lighten-1">Wrapping Paper</div>
    </v-flex>

    <v-flex xs12>
      <v-layout row wrap class="pb-2">

          <v-flex xs12 sm6 class="text-xs-center py-2" style="position:relative;">
            <h2 class="subheading mb-1">Create your own gift wrap</h2>
            <v-layout row justify-center>
              <img class="clickable" @click="$emit('custom')" src="/images/personal.png" :height="100" />
            </v-layout>
            <v-btn color="info" @click="$emit('custom')">Create your own</v-btn>

            <div class="line-side hidden-xs-only"></div>
            <div class="line-bottom hidden-sm-and-up"></div>
          </v-flex>

          <v-flex xs12 sm6 class="text-xs-center py-2">
            <h2 class="subheading mb-1">Use a default gift wrap</h2>
            <v-layout row justify-center>
              <img class="clickable" @click="$emit('default')" src="/images/premade.png" :height="100" />
            </v-layout>
            <v-btn color="info" @click="$emit('default')">Use a default</v-btn>
          </v-flex>

      </v-layout>
    </v-flex>

  </v-layout>

</template>

<script>
export default {
  name:'box-wrap-type'
}
</script>
