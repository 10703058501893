<template>

  <div class="rel-container" style="overflow-y:auto;">

    <input
      type="file"
      style="position:absolute; display:none;"
      accept="image/png, image/jpeg, image/gif, image/jpg"

      ref="fileInput"
      @change="fileSelected"
    >

    <v-btn @click="$refs.fileInput.click()">Select File</v-btn>
    <v-btn color="success" @click="$emit('submit')">Okay</v-btn>

  </div>

</template>

<script>
export default {
  name:'gift-test',
  methods: {
    fileSelected(e) {
      // Copied from vue-cropper code...

      console.log(e);

      var file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert('Please use gif,jpeg,jpg,png,bmp file format.');
        return false;
      }

      this.$emit('file', file);

			// var reader = new FileReader()
			// reader.onload = (e) => {
      //   // raw file data
      //   let data
			// 	if (typeof e.target.result === 'object')
			// 		data = window.URL.createObjectURL(new Blob([e.target.result])); // blob
			// 	else
			// 		data = e.target.result;  // dataURL
      //
      //   // emit data
      //   this.$emit('file', data);
			// }
			// reader.readAsDataURL(file);  // base64
			// //reader.readAsArrayBuffer(file);  // blob

    },
  }
}

/*
files: FileList
0: File(10216)
  lastModified: 1540177263434
  lastModifiedDate: Sun Oct 21 2018 17:01:03 GMT-1000 (Hawaii-Aleutian Standard Time) {}
  name: "card_closed.png"
  size: 10216
  type: "image/png"
  webkitRelativePath: ""
  __proto__: File
length: 1
*/

</script>
