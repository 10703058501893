<template>

  <div class="box-pattern">

    <image-blocks
      :value="image" @input="(src) => $emit('update:image', src)"
      :images="images"
      :padding-top="paddingTop"
      :padding-bottom="paddingBottom"
    ></image-blocks>

    <div class="px-4 py-1">
      <v-slider
        :value="slider"
        @input="(val) => textureOpacityIntent=val"
        @end="(val) => slider=val"

        thumb-color="grey darken-1"
        color="rgba(0,0,0,0)"
        append-icon="lens"
        prepend-icon="panorama_fish_eye"
        :hide-details="true"
        always-dirty
      ></v-slider>
    </div>

    <v-layout align-center class="border-top pa-1 white">
      <v-btn color="grey darken-1" dark @click="$emit('cancel')">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="$emit('submit')">Ok</v-btn>
    </v-layout>

  </div>

</template>

<script>
import ImageBlocks from '@/components/customizer/ImageBlocks.vue'

export default {
  name:'box-pattern',
  components: {
    ImageBlocks,
  },
  props: {
    images:{type:Array, default:() => []},
    image: {type:String, default:null},
    colacity: {type:Object, default:() => {return {color:0, opacity:0.5};}},
    paddingTop: {Type:Number, default:7},
    paddingBottom: {Type:Number, default:5},
  },
  data() {
    return {

      textureOpacityIntent: 0.5,
      textureOpacityFlag:true,
      textureOpacityIntentRefresh: 90,

    }
  },
  watch: {

    textureOpacityIntent() {
      if(this.textureOpacityFlag) {
        this.slider = this.textureOpacityIntent;
        this.textureOpacityFlag = false;
        setTimeout(() => { this.textureOpacityFlag = true; }, this.textureOpacityIntentRefresh);
      }
    },

  },
  computed: {
    slider: {
      get() {
        return this.colacityToValue(this.colacity)
      },
      set(val) {
        this.$emit('update:colacity', this.valueToColacity(val));
      }
    }
  },
  methods: {

    valueToColacity(value) {
      return ( value >= 50 )
      ? { color:1, opacity:((value)/100) }
      : { color:0, opacity:((value)/100) };
    },

    colacityToValue(colacity) {
      return colacity.opacity*100;
    },

    // valueToColacity(value) {
    //   return ( value >= 50 )
    //   ? { color:1, opacity:((value-50)/50) }
    //   : { color:0, opacity:((50-value)/50) };
    // },
    //
    // colacityToValue(colacity) {
    //   return (colacity.color == 0)
    //     ? (50 - colacity.opacity*50)
    //     : (50 + colacity.opacity*50);
    // },

  }
}
</script>

<style scoped>

.v-input--slider {
  margin: 0;
}

</style>
