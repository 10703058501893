<template>

  <v-tabs v-model="tab" dark class="no-bar full-height-tabs">

    <v-tab v-for="n in 2" :key="n" ripple>{{n}}</v-tab>

    <v-tabs-items :touchless="true">

      <!-- Main Tab -->
      <v-tab-item :key="1">

        <v-layout column fill-height>

          <!-- Main Content -->
          <v-flex class="d-flex" align-center>
            <v-layout justify-center>
              <div class="canvas-container" style="background-color:lightgrey;" ref="canvasContainer">
                <builder-canvas ref="canvas"></builder-canvas>
              </div>
            </v-layout>
          </v-flex>

          <!-- Bottom Pane -->
          <div class="pos-rel">
            <v-tabs v-model="actionsTab" dark class="no-bar">

              <v-tab v-for="n in 2" :key="n" ripple>{{n}}</v-tab>

              <!-- Bottom Actions/Confirm Tabs -->
              <v-tabs-items :touchless="true" class="border-top">

                <!-- Bottom Tab 1 -->
                <v-tab-item :key="1">

                  <!-- Actions Pane -->
                  <div class="rel-container">

                    <div class="abs-top-left pa-0_25 grey--text text--lighten-2">
                    </div>

                    <input
                      type="file"
                      style="position:absolute; display:none;"
                      accept="image/png, image/jpeg, image/gif, image/jpg"
                      ref="fileInput"
                      @change="fileSelected"
                    >

                    <div class="center-scroll-x">
                      <builder-actions-button
                        v-for="(item, i) in actions" :key="i"
                        :title="item.title" :icon="item.icon" :event="item.event"
                        @click="buttonPressed"
                      >
                      </builder-actions-button>
                    </div>

                  </div>

                  <!-- Confirm Pane -->
                  <v-layout align-center justify-center class="border-top pa-2" style="background-color:#CECECE">
                    <v-btn color="grey darken-1" dark @click="$emit('cancel')">Close</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="() => { $emit('submit', {data:exportData(), src:exportCanvas()}); }">Ok</v-btn>
                  </v-layout>

                </v-tab-item>

                <!-- Bottom Tab 2 -->
                <v-tab-item :key="2">

                  <!-- Actions Pane -->
                  <div class="rel-container">
                    <!--
                    :colors="colors"
                    -->
                    <color-blocks
                    :value="color"
                    @input="(col) => {color = col;}"
                    :padding-top="20"
                    :padding-bottom="15"
                    ></color-blocks>
                  </div>

                  <!-- Confirm Pane -->
                  <v-layout align-center justify-center class="border-top pb-1" style="background-color:#CECECE">
                    <v-btn color="success" @click="actionsTab=0">Ok</v-btn>
                  </v-layout>

                </v-tab-item>

              </v-tabs-items>

            </v-tabs>
          </div>

        </v-layout>

      </v-tab-item>

      <!-- Texture Tab -->
      <v-tab-item :key="2">

        <v-layout column fill-height>

          <!-- Texture Main Content -->
          <v-flex class="d-flex" align-center>
            <v-layout justify-center>
              <div class="canvas-container" style="background-color:lightgrey;" ref="smallCanvasContainer">
                <builder-texture-canvas
                  :src="textureSrc"
                  :opacity="textureOpacity"
                  :color="textureColor"
                  :background="color"
                  ref="smallCanvas"
                ></builder-texture-canvas>
              </div>
            </v-layout>
          </v-flex>

          <!-- Texture Bottom Pane -->
          <div class="border-top">

            <!--
            :value="image"
            @input="(src) => $emit('update:image', src)"
            :value="textureSrc"
            @input="(src) => {textureSrc = src;}"
            -->
            <image-blocks
              :value="textureSrc"
              @input="(src) => { textureSrc = src; }"
              :images="wraps"
            ></image-blocks>

            <div class="pb-2 pt-3">

              <v-slider
                :value="textureOpacityIntent*100"
                @input="(val) => { textureOpacityIntent = val/100; }"

                :hide-details="true"
                thumb-color="grey darken-1"
                color="rgba(0,0,0,0)"
                append-icon="lens"
                prepend-icon="panorama_fish_eye"
                always-dirty
              >
            </v-slider>

            </div>

            <color-blocks
            :value="textureColor"
            @input="(col) => {textureColor = col;}"
            ></color-blocks>

            <!-- Confirm Pane -->
            <v-layout align-center justify-center class="border-top pa-2" style="background-color:#CECECE">
              <v-btn color="success" @click="() => { tab=0; $emit('exportData', exportData()) }">Ok</v-btn>
            </v-layout>

          </div>

        </v-layout>

      </v-tab-item>

    </v-tabs-items>

  </v-tabs>

</template>

<script>
import {builder as builderContent}  from '@/static/customizer'

import heap from '@/libs/heap'

import BuilderActionsButton from './BuilderActionsButton.vue'
import BuilderCanvas from './BuilderCanvas.vue'
import BuilderTextureCanvas from './BuilderTextureCanvas.vue'
import ColorBlocks from '@/components/customizer/ColorBlocks.vue'
import ImageBlocks from '@/components/customizer/ImageBlocks.vue'

import {wrapTextures} from '@/static/customizer'

export default {
  name:'builder-main',
  components: {
    BuilderActionsButton,
    BuilderCanvas,
    BuilderTextureCanvas,
    ColorBlocks,
    ImageBlocks,
  },
  props: {
    active:false,
    initData:{type:Object, default:null},
  },
  data() {
    return {
      tab:0,

      actions:builderContent.main.actions,
      actionsTab:0,

      textureSrc : '/assets/wrap-textures/dots.png',
      textureOpacity : 0.5,
      textureColor : 'white',
      color : '#3C82F0',

      textureOpacityIntent: 0.5,
      textureOpacityFlag:true,
      textureOpacityIntentRefresh: 90,

      wraps:wrapTextures,
    }
  },
  computed:{
    initialized() {
      return this.$refs.canvas.initialized;
    },
    smallInitialized() {
      return this.$refs.smallCanvas.initialized;
    },
  },
  watch: {

    textureOpacityIntent() {
      if(this.textureOpacityFlag) {
        this.textureOpacity = this.textureOpacityIntent;
        this.textureOpacityFlag = false;
        setTimeout(() => { this.textureOpacityFlag = true; }, this.textureOpacityIntentRefresh);
      }
    },

    active() {
      if(this.active && !this.initialized) { this.init(); }
    },

    tab() {
      if( this.tab == 1 && !this.smallInitialized ) { this.smallInit(); }
    },

    color() { this.$refs.canvas.color = this.color; },
    textureSrc() { this.$refs.canvas.textureSrc = this.textureSrc; },
    textureOpacity() { this.$refs.canvas.textureOpacity = this.textureOpacity; },
    textureColor() { this.$refs.canvas.textureColor = this.textureColor; },

  },
  mounted() {
    if(this.active && !this.initialized) { this.init(); }
  },
  methods: {

    exportCanvas() {
      let dataURL = this.$refs.canvas.exportCanvas();
      return dataURL;
    },

    exportData() {
      let data = this.$refs.canvas.exportData();

      // current sources ~ { index : url }
      let srcMap = {};
      for(let i in data.items) { srcMap[i] = data.items[i].src; }

      // unique urls ~ { url : count } (count will be the new file name)
      let blobNames = {};
      let c = 0;
      for(let i in srcMap) {
        let url = srcMap[i];
        if( !(url in blobNames) && heap.file(url) ) { blobNames[url] = c; c++; }
      }

      // write file w/ name=count.png for each unique url
      for(let i in blobNames) {
        let name = blobNames[i];
        heap.write(heap.file(i).file, name + '.png', '/gift/personal/builder/items');
      }

      // name for each index ~ { index : count }
      let nameMap = {};
      for(let i in srcMap) {
        nameMap[i] = blobNames[ srcMap[i] ];
      }

      // generate builderPings and save to session storage
      sessionStorage.setItem( 'builderPings', JSON.stringify(nameMap) );

      return data;

      // manually update store module
      //this.$store.commit('setCustomizerStageProperty', {key:'personal.builder', value:data});

      // debug
      // console.log( '===========' );
      // console.log( data );
      // console.log( srcMap );
      // console.log( blobNames );
      // console.log( nameMap );
      // console.log( '===========' );
    },

    init() {
      this.$nextTick(() => {
      this.$nextTick(() => {  // extra tick for flex box loading
        this.resizeCanvasContainer();
        this.$refs.canvas.init(this.initData);

        if(this.initData) {
          this.color = this.initData.color;
          this.textureOpacity = this.initData.texture.opacity;
          this.textureColor = this.initData.texture.color;
          this.textureSrc = this.initData.texture.src;
        }

        // sync BuilderCanvas
        this.$refs.canvas.color = this.color;
        this.$refs.canvas.textureOpacity = this.textureOpacity;
        this.$refs.canvas.textureColor = this.textureColor;
        this.$refs.canvas.textureSrc = this.textureSrc;

      });
      });
    },

    smallInit() {
      this.$nextTick(() => {
      this.$nextTick(() => {  // extra tick for flex box loading
        this.resizeSmallCanvasContainer();
        this.$refs.smallCanvas.init();
      });
      });
    },

    addImage(dataURL, cb) {

      // HERE - builderMain.addImage ~ where uploaded files end up (builder)

      // NOTE: src is a blob url
      let src = heap.putData( dataURL, Date.now(), '/gift/wrap' );

      // TODO: send an actual file this way instead of dataURL ~ can't ~ the cropper module exports a dataURL ~ need to convert dataURL to blob here

      this.$refs.canvas.add(src, cb);
    },

    resizeCanvasContainer() {
      let container = this.$refs.canvasContainer;

      // parent node for sizing
      let parent = container.parentNode.parentNode;
      let width = parent.offsetWidth - 32;
      let height = parent.offsetHeight  - 32;

      let size = (width<=height) ? width : height;

      // set container size
      container.style.width  = size + 'px';
      container.style.height = size + 'px';
    },

    resizeSmallCanvasContainer() {
      let container = this.$refs.smallCanvasContainer;

      // parent node for sizing
      let parent = container.parentNode.parentNode;
      let width = parent.offsetWidth - 32;
      let height = parent.offsetHeight  - 32;

      let size = (width<=height) ? width : height;

      // set container size
      container.style.width  = size + 'px';
      container.style.height = size + 'px';
    },

    fileSelected(e) {
      // Copied from vue-cropper code...

      var file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert('Please use gif,jpeg,jpg,png,bmp file format.');
        return false;
      }

      // HERE - when image is uploaded to builder

      console.log('file:');
      console.log(file);

      let blobUrl = window.URL.createObjectURL(file);
      let imageInfo = { name: file.name, src: blobUrl, file };
      this.$emit('image', imageInfo);

    },

    buttonPressed(e) {

      switch(e) {
        case 'image': this.$refs.fileInput.click(); return;
        case 'color': this.actionsTab = 1; return;
        case 'texture': this.tab = 1; return;

        default: console.log('NO EVENT HANDLER FOR ' + e + '. SEE buttonPressed() METHOD.');
      }

    }

  }
}
</script>

<style scoped>

</style>
