<template>

  <div class="gift-tango-card">

    <h2 class="subheading">Tango Card</h2>

    <div class="pt-2" style="text-align:center;">

      <div class="grey--text">
        Gift Card Amount
      </div>
      <div style="max-width:200px; margin:auto;">
        <v-text-field
          type="number"
          prefix="$"
          min="0"
          step="1"
          pattern="[0-9]"
          solo
          append-outer-icon="add"
          prepend-icon="remove"
          @click:append-outer="() => { amount++; onInput(amount); }"
          @click:prepend="() => { amount--; onInput(amount); }"
          :value="amount"
          @input="onInput"
          :rules="[rules.max, rules.min]"
        ></v-text-field>
      </div>

    </div>

  </div>

</template>

<script>
import { MIN_TANGO_CARD_AMOUNT, MAX_TANGO_CARD_AMOUNT } from '@/static/customizer'

export default {
  name:'gift-tango-card',
  data() {

    let amountInitial = this.$store.getters.customizerStageProperty('contentInfo.amount');
    if(!(amountInitial || amountInitial == 0)) {
      amountInitial = 40;
    }

    return {
      max: MAX_TANGO_CARD_AMOUNT,
      min: MIN_TANGO_CARD_AMOUNT,
      amount: amountInitial,
      rules: {
        max: value => (value <= MAX_TANGO_CARD_AMOUNT || '$' + MAX_TANGO_CARD_AMOUNT + ' limit'),
        min: value => (value >= MIN_TANGO_CARD_AMOUNT || '$' + MIN_TANGO_CARD_AMOUNT + ' minimum'),
      },
    };

  },
  methods: {

    getData() {
      this.$emit('getData', { amount: this.amount });
    },

    onInput(value) {
      let val = value;
      if(value < 0) { val = 0; }
      if(value > MAX_TANGO_CARD_AMOUNT) { val = MAX_TANGO_CARD_AMOUNT; }
      this.amount = Math.ceil(val);
    },

  },

  // debug
  // mounted() {
  //   window.gtc = this;
  // },

}
</script>
